import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { testimonialsTitle, testimonials } from '../../data/AboutMePageData';
import { EffectFlip, Navigation, Pagination } from 'swiper';

const Testimonials = () => {
    return (
        <section className="border-t-4 border-primary pt-10">
            <div className="eagle text-4xl mb-12">{testimonialsTitle}</div>
            <Swiper
                effect={'flip'}
                rewind
                grabCursor={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[EffectFlip, Navigation, Pagination]}
                className='w-[95%] lg:w-1/2 xl:w-1/3 flex flex-col items-center'
            >
                {testimonials.map((testimonial) => (
                    <SwiperSlide key={testimonial.title}>
                        <div className="flex flex-col justify-between items-end bg-primary text-tertiary p-8 h-full min-h-[700px]">
                            <div className="flex flex-col gap-8">
                                <h3 className="eagle text-3xl">{testimonial.title}</h3>
                                <p className="text-base sm:text-2xl text-justify">{testimonial.content}</p>
                            </div>
                            <p className="text-xl italic">{testimonial.author}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};
export { Testimonials };
