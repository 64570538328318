import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideInText } from '../../animations/animations';

const STYLES = 'pt-5 sm:pt-8 lg:pt-10 pb-2 lg:pb-4 eagle';

type Props = {
    index: number;
    title: string;
    url: string;
    delay: number;
    onHover: (index: number) => void;
};

const NavText = ({ index, title, url, delay, onHover }: Props) => {
    return (
        <motion.li
            className="flex flex-row items-end gap-3 lg:gap-6 border-b border-tertiary mx-8 "
            onMouseEnter={() => onHover(index)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
        >
            <motion.div
                className="flex flex-row items-end gap-3 lg:gap-4 mx-4 text-tertiary hover:text-black"
                initial="hidden"
                animate="visible"
                variants={slideInText(delay)}
            >
                <span
                    className={`${STYLES} texl-xs sm:text-xl 2xl:text-2xl`}
                >{`0${index}/`}</span>
                <Link to={`/${url}`} className="grow">
                    <motion.h3
                        className={`${STYLES} text-xs sm:text-3xl 2xl:text-5xl font-bold`}
                        whileHover={{
                            marginLeft: 10,
                            transition: { duration: 0.3 },
                        }}
                    >
                        {title}
                    </motion.h3>
                </Link>
            </motion.div>
        </motion.li>
    );
};
export { NavText };
