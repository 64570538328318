import { TextColor } from "../../types/types";

type Props = {
    title: string;
    color: TextColor
};

const ViewTitle = ({ title, color }: Props) => {
    return (
        <h1 className={`eagle text-4xl md:text-7xl lg:text-9xl text-center flex flex-row justify-center ${color}`}>
            {title}
        </h1>
    );
};
export { ViewTitle };
