import { BaseSyntheticEvent, useState } from 'react';
import { SearchIcon } from '../shared/SVGs';

type Props = {
    onSearchClick: (keyword: string) => void;
};

const SearchBar = ({ onSearchClick }: Props) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: BaseSyntheticEvent) => {
        setInputValue(e.target.value);
    };

    return (
        <div className="relative h-10 flex flex-row gap-2">
            <span className="absolute left-2 top-1">
                <SearchIcon />
            </span>
            <input
                value={inputValue}
                className="grow pl-12"
                type="text"
                onChange={handleInputChange}
            />
            <button
                className="bg-funky hover:bg-tertiary font-bold text-tertiary hover:text-funky transition-colors duration-300 hover:border-funky px-4"
                type='submit'
                onClick={() => onSearchClick(inputValue)}
            >
                Hľadaj
            </button>
        </div>
    );
};
export { SearchBar };
