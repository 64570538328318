import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { aboutTCMSectionData } from '../../data/LandingPageData';
import {
    Color,
    Direction,
    SectionProps,
    SECTION_COLOR_VARIANTS,
} from '../../types/types';
import { Heading } from '../shared/Heading';
import { Section } from '../shared/Section';
import { AboutTCMHero } from './AboutTCMHero';
import { fadeIn, fadeOut, slideIn } from '../../animations/animations';
import { ActionButton } from '../shared/ActionButton';
import { Marquee } from './Marquee';

const AboutTCMSection = ({ color }: SectionProps) => {
    const [heroAnimationFinished, setHeroAnimationFinished] = useState(false);
    return (
        <Section id="02" classNames={SECTION_COLOR_VARIANTS[color]}>
            <Heading value={aboutTCMSectionData.title} />
            <div className="flex flex-col-reverse xl:flex-row justify-end gap-20">
                <AnimatePresence>
                    {heroAnimationFinished && (
                        <div className="flex flex-col gap-16">
                            <article className="flex flex-col gap-8 md:text-3xl">
                                {aboutTCMSectionData.paragraphs.map(
                                    (para, idx) => (
                                        <motion.p
                                            key={para}
                                            initial="hidden"
                                            animate="visible"
                                            variants={fadeIn(0.5, idx * 0.5)}
                                            exit={fadeOut(0.2)}
                                        >
                                            {para}
                                        </motion.p>
                                    )
                                )}
                            </article>
                            <div className="flex flex-row justify-start">
                                <ActionButton
                                    color={Color.TERTIARY_LIGHT}
                                    text="Viac o TČM"
                                    url="o-tcm"
                                    variants={slideIn(Direction.LEFT, 1, 0.5)}
                                />
                            </div>
                        </div>
                    )}
                </AnimatePresence>
                <AboutTCMHero
                    image={aboutTCMSectionData.heroImage}
                    setHeroAnimationFinished={setHeroAnimationFinished}
                />
            </div>
            <Marquee content={aboutTCMSectionData.marqueeContent} />
        </Section>
    );
};
export { AboutTCMSection };
