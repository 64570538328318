import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import { contactSectionData } from '../../data/LandingPageData';
import { SectionProps, SECTION_COLOR_VARIANTS } from '../../types/types';
import { Heading } from './Heading';
import { Facebook, Instagram } from './SVGs';
import { Section } from './Section';
import { GDPR } from './GDPR';

type Props = {
    bigHeading?: boolean;
} & SectionProps;

const ContactSection = ({ color, bigHeading }: Props) => {
    const [showGDPR, setShowGDPR] = useState(false);

    return (
        <>
            <Section id="05" classNames={SECTION_COLOR_VARIANTS[color]}>
                {bigHeading && <Heading value={contactSectionData.title} />}
                {!bigHeading && (
                    <h2 className="eagle text-tertiary text-5xl -my-8 text-center">
                        {contactSectionData.title}
                    </h2>
                )}
                <div className="w-full xl:w-2/3 flex flex-col-reverse lg:flex-row justify-between items-center gap-8 lg:gap-4 text-sm sm:text-2xl border-2 border-tertiary py-8 px-8 sm:px-16 mx-auto">
                    <div className="flex flex-col gap-4">
                        <div className="">
                            {contactSectionData.companyInfo.map((item) => (
                                <p key={uuid()}>{item}</p>
                            ))}
                        </div>
                        <div>
                            {contactSectionData.contactInfo.map((item) => (
                                <p key={uuid()}>{item}</p>
                            ))}
                        </div>
                        <div className="flex flex-row gap-4">
                            <a
                                href="https://www.facebook.com/profile.php?id=100064082737459"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Facebook />
                            </a>
                            <a
                                href="https://www.instagram.com/fyt_spirit/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Instagram />
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <img
                            src={contactSectionData.logo.src}
                            alt={contactSectionData.logo.alt}
                        />
                    </div>
                </div>
                <div className="absolute w-full bottom-4 left-1/2 -translate-x-1/2 flex flex-col-reverse md:flex-row justify-center items-center gap-1 text-sm">
                    <span className="md:pr-8 md:border-r md:border-tertiary">
                        Web by Tommy Nagy &copy; 2023
                    </span>
                    <span
                        className="md:pl-8 cursor-pointer"
                        onClick={() => setShowGDPR(true)}
                    >
                        Ochrana osobných údajov
                    </span>
                </div>
            </Section>
            {showGDPR && <GDPR onOutClick={() => setShowGDPR(false)} />}
        </>
    );
};
export { ContactSection };
