/*******************************************
 * 
 *                  ENUMS
 * 
 *******************************************/

export enum SectionID {
    ABOUT_ME = 'aboutme',
    ABOUT_TCM = 'abouttcm',
    THERAPY = 'therapy',
    BLOG = 'blog',
    CONTACT = 'contact'
}

export enum Direction {
    LEFT = 'left',
    RIGHT = 'right',
    UP = 'up',
    DOWN = 'down',
}

export enum Background {
    PRIMARY = 'bg-primary',
    SECONDARY = 'bg-secondary',
    TERTIARY = 'bg-tertiary',
    DARK = 'bg-dark',
    FUNKY = 'bg-funky',
}

export enum TextColor {
    PRIMARY = 'text-primary',
    SECONDARY = 'text-secondary',
    TERTIARY = 'text-tertiary',
    DARK = 'text-dark',
    FUNKY = 'text-funky',
}

export enum BlogContentType {
    IMAGE,
    SUBTITLE,
    PARAGRAPH,
    LIST,
}

export enum Color {
    PRIMARY,
    SECONDARY,
    TERTIARY_DARK,
    TERTIARY_LIGHT,
    FUNKY,
    FUNKY_REVERSE,
    DARK,
}

/*******************************************
 * 
 *              CONSTANTS
 * 
 *******************************************/

export const SECTION_COLOR_VARIANTS = {
    [Color.PRIMARY]: 'bg-primary text-tertiary',
    [Color.SECONDARY]: 'bg-secondary text-tertiary',
    [Color.TERTIARY_DARK]: 'bg-tertiary text-secondary',
    [Color.TERTIARY_LIGHT]: 'bg-tertiary text-primary',
    [Color.FUNKY]: 'bg-funky text-tertiary',
    [Color.FUNKY_REVERSE]: 'bg-tertiary text-funky',
    [Color.DARK]: 'bg-dark text-tertiary',
}

export const BUTTON_COLOR_VARIANTS = {
    [Color.PRIMARY]: 'bg-primary text-tertiary hover:bg-tertiary hover:text-primary border-2 hover:border-2 hover:border-primary hover:transition-colors duration-700',
    [Color.SECONDARY]: 'bg-secondary text-tertiary hover:bg-tertiary hover:text-secondary border-2 hover:border-2 hover:border-secondary hover:transition-colors duration-700',
    [Color.TERTIARY_DARK]: 'bg-tertiary text-secondary hover:bg-secondary hover:text-tertiary border-2 hover:border-2 hover:border-tertiary hover:transition-colors duration-700',
    [Color.TERTIARY_LIGHT]: 'bg-tertiary text-primary hover:bg-primary hover:text-tertiary border-2 hover:border-2 hover:border-tertiary hover:transition-colors duration-700',
    [Color.FUNKY]: 'bg-funky text-tertiary hover:bg-tertiary hover:text-funky border-2 hover:border-2 hover:border-funky hover:transition-colors duration-700',
    [Color.FUNKY_REVERSE]: 'bg-tertiary text-funky hover:bg-funky hover:text-funky border-2 hover:border-2 hover:border-tertiary hover:transition-colors duration-700',
    [Color.DARK]: 'bg-dark text-tertiary hover:bg-tertiary hover:text-dark border-2 hover:border-2 hover:border-dark hover:transition-colors duration-700',
}

/*******************************************
 * 
 *              TYPE DEFINITIONS
 * 
 *******************************************/

export type SectionProps = {
    color: Color;
}

export type ExitBtnProps = {
    onExitClick: () => void;
    rightSide?: boolean;
};

export type Testimonial = {
    author: string;
    title: string;
    content: string;
}

export type BlogCardContent = {
    title: string;
    excerpt: string;
    thumbnail: string;
    url: string;
}

export type BlogContentObj = {
    type: BlogContentType;
    content: string | string[];
}

export type BlogPostType = {
    slug: string;
    title: string;
    topImage: string;
    lede: string;
    excerpt: string;
    content: BlogContentObj[];
    categories: string[];
    date: Date;
    isRecommended: boolean;
};

export type Category = {
    name: string;
    amount: number;
};

export type Photo = {
    src: string;
    alt: string;
}

export type Price = {
    item: string;
    price: number;
}

export type RecommendedCardPropType = {
    title: string;
    slug: string;
};

export type TimelineContentType = {
    date: string;
    title: string;
    content: string[];
    footer: string;
}

export type AboutTCMContentItem = {
    color: Color;
    title: string;
    image: string;
    content: string[]
}

export type AboutFYTSpiritFAQType = {
    question: string;
    answer: string[];
    image?: Photo;
}

export type WindowSize = {
    width: number | undefined;
    height: number | undefined;
}