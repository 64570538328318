import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { fadeIn, slideIn } from '../../animations/animations';
import { blogSectionData } from '../../data/LandingPageData';
import { sortedBlogPosts as blogPosts } from '../../data/BlogPageData';
import {
    SectionProps,
    SECTION_COLOR_VARIANTS,
    Color,
    Direction,
} from '../../types/types';
import { ActionButton } from '../shared/ActionButton';
import { BlogCard } from './BlogCard';
import { Heading } from '../shared/Heading';
import { Section } from '../shared/Section';

const BlogSection = ({ color }: SectionProps) => {
    return (
        <Section id="04" classNames={SECTION_COLOR_VARIANTS[color]}>
            <Heading value={blogSectionData.title} />
            <div className="flex flex-col xl:flex-row items-center gap-16 xl:gap-8">
                <div className="xl:w-1/2 flex flex-col justify-between gap-16">
                    <article className="flex flex-col gap-8">
                        {blogSectionData.paragraphs.map((p) => (
                            <p key={uuid()} className="md:text-3xl">
                                {p}
                            </p>
                        ))}
                    </article>
                    <div>
                        <ActionButton
                            color={Color.TERTIARY_DARK}
                            text="Viac blogov"
                            url="blog"
                            variants={slideIn(Direction.LEFT, 1, 0.4)}
                        />
                    </div>
                </div>
                <motion.div
                    className="min-[850px]:w-2/3 xl:w-1/2 h-auto flex flex-col gap-16 xl:flex-row justify-center"
                    initial="hidden"
                    whileInView="visible"
                    variants={fadeIn(1.5)}
                    viewport={{ once: true }}
                >
                    <BlogCard
                        blogCardContents={blogPosts}
                    />
                </motion.div>
            </div>
        </Section>
    );
};
export { BlogSection };
