import { BlogPostType } from "../types/types";

const getPrettyDate = (date: Date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear()

    return `${day}. ${month}. ${year}`
}

const fetchPost = (params: string, posts: BlogPostType[]) => {
    return posts.find(post => post.slug === params)
}

export { getPrettyDate, fetchPost }