import { motion } from 'framer-motion';
import { slideUp } from '../../animations/animations';
import { Photo } from '../../types/types';

type Props = {
    photo: Photo
};

const HeroImage = ({ photo }: Props) => {
    return (
        <motion.div
            className="w-full"
            initial="hidden"
            animate="visible"
            variants={slideUp(0.8)}
        >
            <img className="w-full" src={photo.src} alt={photo.alt} />
        </motion.div>
    );
};
export { HeroImage };
