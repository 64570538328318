import { BlogContentObj, BlogContentType } from "../types/types";

type GDPR = {
    title: string;
    content: BlogContentObj[]
}

const GDPRData: GDPR = {
    title: 'Ochrana dát',
    content: [
        {
            type: BlogContentType.PARAGRAPH,
            content: 'V zmysle článku 19 a nasl. zákona č. 18/2018 Zb. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov v znení neskorších predpisov v súlade s článkami 13 a 14 nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pokiaľ ide o spracovanie osobných údajov a voľný pohyb takýchto údajov a ktorou sa ruší smernica 95/46 / ES (nariadenie o všeobecnej ochrane údajov) (ďalej len „GDPR“). Podľa ustanovení článku 19 a nasl. zákona č. 18/2018 Zb. o ochrane osobných údajov v znení neskorších predpisov (ďalej len „zákon o ochrane osobných údajov“), týmto vám ako subjektu údajov poskytneme nasledujúce informácie o spracovaní vašich osobných údajov.'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Dohľad: Mgr. Katarína Nagyová Šnircová so sídlom na adrese Jána Ondruša 2790/13A, 90031, Bratislava, IČO 47103396 (ďalej len „kontrolór“).'
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Kontaktné údaje:'
        },
        {
            type: BlogContentType.LIST,
            content: [
                'E-mail: fytspirit@gmail.com',
                'Telefón: +421917108800'
            ]
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Prístup k osobným údajom:'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Pri spracúvaní osobných údajov môžu okrem spracovateľa mať k osobným údajom aj iné osoby spracúvajúce osobné údaje ako spracovatelia. Špecifický priebežne aktualizovaný zoznam procesorov, t. j. príjemcov osobných údajov spracovaných v mene kontrolóra, v nasledujúcich kategóriách:'
        },
        {
            type: BlogContentType.LIST,
            content: [
                'Spoločnosti poskytujúce marketingové služby',
                'Spoločnosti poskytujúce mzdové a účtovné služby',
                'Spoločnosti poskytujúce služby interného a externého auditu',
            ]
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Právny základ:'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Právnym základom na spracovanie osobných údajov je spravidla splnenie zákonnej povinnosti (najmä zákona č. 18/2018 Zb. O ochrane osobných údajov), uzavretie, správa a plnenie zmluvných záväzkov vyplývajúcich zo zamestnania a obchodných zmlúv, legitímny záujem, ktorým je ochrana práv a záujmov kontrolóra a jeho zamestnancov chránených právom, ako aj súhlas dotknutej osoby (najmä na účely marketingu, súťaží, spoločenských podujatí atď.). Súhlas so spracovaním osobných údajov musí byť udelený dobrovoľne a dotknutá osoba môže kedykoľvek odobrať súhlas. Súhlas je však nevyhnutný pre kontrolóra, aby zabezpečil napríklad firemné udalosti a podobne a bez takéhoto súhlasu nemá kontrolór právo poskytovať príslušné služby. Ak subjekt údajov odmietne poskytnúť osobný údaj, ktorý je potrebný na plnenie zmluvných povinností prevádzkovateľa alebo dodržiavať zákon, kontrolór nie je povinný uzavrieť zmluvu alebo poskytovať iné služby.'
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Spracované osobné údaje:'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Zhromažďujeme meno zákazníka, e-mailovú adresu, adresu dodania a fakturácie, údaje o platbe, názov spoločnosti, telefónne číslo, adresu IP, informácie o zadaných objednávkach, informácie o obchodoch s obchodníkmi podporovanými kontolórom. Tieto informácie zhromažďujeme, keď používate alebo vstúpite do obchodu, ktorý využíva naše služby, napríklad keď navštívite stránku obchodníka, zadáte objednávku alebo zaregistrujte účet na obchodnom mieste. Počas návštevy alebo prehliadania webovej stránky Fytspirit zhromažďujeme informácie o používanom zariadení a prehliadači, vašom sieťovom pripojení, adrese IP a informáciách o súboroch cookie nainštalovaných vo vašom zariadení. Takisto zhromažďujeme osobné informácie, ktoré ste predložili prostredníctvom ľubovoľnej funkcie na odosielanie správ, ktorá je k dispozícii na našich webových stránkach. Od používateľov fóra zhromažďujeme vaše meno, e-mailovú adresu, adresu URL webovej stránky a ďalšie osobné informácie, ktoré môžete uverejniť.'
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Účel spracovania a zdieľania:'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Tieto informácie používame na poskytovanie služieb našim obchodníkom vrátane podpory a spracovania objednávok, overovania a platieb. Tieto informácie používame aj na zlepšenie našich služieb. Katarína Nagyová Šnircová spolupracuje aj s tretími stranami a poskytovateľmi služieb, aby našim obchodníkom poskytovali služby a môžeme s nimi zdieľať osobné informácie na podporu týchto snáh. Vaše informácie môžeme zdieľať aj za nasledujúcich okolností:'
        },
        {
            type: BlogContentType.LIST,
            content: [
                'predchádzanie, vyšetrovanie alebo konanie proti nezákonným činnostiam, podozreniam z podvodu, situáciám s potenciálnym ohrozením fyzickej bezpečnosti akejkoľvek osoby, porušovaním našich Podmienok používania alebo akejkoľvek inej dohody súvisiacej so službami alebo zákonom inak,',
                'ak obchodník, ktorého obchod navštívite alebo k nemu máte prístup, nás nasmeruje na prenos týchto informácií (napríklad ak povolia aplikáciu tretej strany, ktorá pristupuje k osobným informáciám zákazníka),',
                'dodržiavanie zákonných požiadaviek alebo reagovanie na zákonné súdne príkazy, predvolania, opačné listy alebo iné požiadavky verejných orgánov.',
            ],
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Osobné informácie môžu byť zdieľané aj so spoločnosťou, ktorá nadobúda našu obchodnú činnosť alebo obchodovanie s obchodníkom, ktorého obchod navštevujete alebo ku ktorému máte prístup prostredníctvom zlúčenia, akvizície, bankrotu, likvidácie, reorganizácie alebo inej podobnej transakcie alebo konania.'
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Uchovávanie osobných údajov a trvanie spracovania:'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Prevádzkovateľ je oprávnený spracúvať osobné údaje dotknutých osôb po dobu určenú v súlade s príslušnými právnymi predpismi. Spracovanie osobných údajov je možné len na obdobie, na ktoré bol súhlas udelený. Súhlas sa poskytne kontrolórovi na nasledujúce tri (3) roky alebo do dátumu odňatia súhlasu. Po uplynutí príslušného obdobia sa osobné údaje vymažú, ak sa ich skladovanie podľa príslušných slovenských právnych predpisov nevyžaduje.'
        },
        {
            type: BlogContentType.SUBTITLE,
            content: 'Subjekt údajov súvisiaci so spracovaním osobných údajov:'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Pokiaľ ide o spracovanie osobných údajov, dotknutá osoba môže uplatňovať tieto práva:'
        },
        {
            type: BlogContentType.LIST,
            content: [
                'právo na prístup a poskytnutie informácií o ich osobných údajoch;',
                'právo na opravu osobných údajov;',
                'právo na vymazanie osobných údajov;',
                'právo na obmedzenie spracovania osobných údajov;',
                'právo na prenosnosť osobných údajov;',
                'právo vzniesť námietky voči spracovaniu osobných údajov;',
                'právo na neefektívnosť automatizovaného individuálneho rozhodovania vrátane profilovania;',
                'právo odobrať súhlas kedykoľvek (ak súhlas je právnym základom spracovania);',
                'právo podať návrh na začatie konania podľa článku 100 a nasl. zákona o ochrane osobných údajov s orgánom dohľadu, Úradom na ochranu osobných údajov Slovenskej republiky so sídlom Hraničná 12, 820 07 Bratislava 27, Slovenská republika, kontaktné údaje: +421 2 3231 3214.',
            ],
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Subjekt údajov môže podliehať ustanoveniam článkov 21 a nasl. zákona o ochrane osobných údajov v súlade so zákonom o ochrane osobných údajov a GDPR, ako aj inými relevantnými právnymi predpismi. Dotknuté osoby môžu uplatniť svoje práva voči kontrolórovi na základe písomnej žiadosti zaslanej alebo doručenej do sídla kontrolóra: Jána Ondruša 2790/13A , Stupava, 90031 alebo elektronickou poštou na adresu: fytspirit@gmail.com.'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Žiadosť musí obsahovať nasledujúce informácie: meno, priezvisko, dátum narodenia a trvalý pobyt, aby mohol kontrolór identifikovať dotknutú osobu. Kontrolór môže požiadať o poskytnutie dodatočných informácií potrebných na overenie totožnosti dotknutej osoby, ak má dôvodné pochybnosti o totožnosti fyzickej osoby.'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Správca je povinný poskytnúť dotknutej osobe informácie o opatreniach prijatých na jej žiadosť do jedného (1) mesiaca od prijatia žiadosti. V odôvodnených prípadoch, pokiaľ ide o zložitosť a počet žiadostí, môže kontrolór predĺžiť vyššie uvedené obdobie o ďalšie dva (2) mesiace, dokonca opakovane. Prevádzkovateľ je povinný informovať dotknuté osoby o takomto predĺžení do jedného (1) mesiaca od doručenia žiadosti, pričom uvedie dôvody na predĺženie lehoty. Ak dotknutá osoba predložila žiadosť v elektronickej forme, prevádzkovateľ poskytne informácie v elektronickej forme, pokiaľ subjekt údajov nepožiadal o informácie iným spôsobom.'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Ak kontrolór na žiadosť dotknutej osoby neprijme opatrenia, je povinný informovať dotknutú osobu o dôvodoch nečinnosti v lehote jedného (1) mesiaca od doručenia žiadosti, ako aj o možnosti podania žiadosti návrh na začatie konania podľa článku 100 zákona o ochrane osobných údajov s Úradom na ochranu osobných údajov. Oznámenia o prijatých opatreniach sa poskytujú bezplatne. Ak je žiadosť dotknutej osoby zjavne neopodstatnená alebo nevhodná, najmä kvôli jej opakujúcej sa povahe, môže prevádzkovateľ požadovať primeraný poplatok, ktorý zohľadní administratívne náklady na poskytnutie informácií alebo primeraný poplatok zohľadňujúci administratívne náklady na oznámenie alebo primeraný poplatok s prihliadnutím na administratívne náklady na vykonanie požadovaného opatrenia alebo odmietnutie konať na žiadosť. Kontrolór preukáže, že žiadosť je neopodstatnená alebo nevhodná.'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Katarína Nagyová Šnircová v prípade potreby podnikne primerané kroky, ktoré vám umožnia prístup k vašim osobným informáciám, ich opravu, zmenu, odstránenie alebo obmedzenie. Ak ste obchodník alebo partner, môžete aktualizovať mnoho typov osobných informácií, ako sú platobné alebo kontaktné informácie, priamo v nastaveniach účtu. Ak nemôžete zmeniť svoje osobné údaje v nastaveniach účtu alebo ak máte obavy z údajov získaných pri návšteve webových stránok Fytspirit alebo z našich služieb podpory, kontaktujte nás, aby ste vykonali požadované zmeny. Je dôležité mať na pamäti, že ak odstránite alebo obmedzíte používanie vašich osobných informácií, služby nemusia fungovať správne.'
        },
        {
            type: BlogContentType.PARAGRAPH,
            content: 'Ak máte akékoľvek otázky týkajúce sa vašich osobných údajov alebo týchto pravidiel, alebo chcete podať sťažnosť na to, ako Katarína Nagyová Šnircová spracováva vaše osobné údaje, obráťte sa na ňu e-mailom na adresu fytspirit@gmail.com.'
        },
    ]
}

export { GDPRData }