import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { fadeIn } from '../../animations/animations';
import { BlogPostType, BUTTON_COLOR_VARIANTS, Color } from '../../types/types';
import { LeftArrow, RightArrow } from '../shared/SVGs';
import { useWindowSize } from '../../hooks/useWindowSize';

type Props = {
    blogCardContents: BlogPostType[];
};

const containerVariants = {
    hidden: {
        opacity: 1,
    },
    visible: {
        opacity: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.3,
        },
    },
};

const itemVariants = {
    hidden: {
        y: 20,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.3,
        },
    },
};

const BlogCard = ({ blogCardContents }: Props) => {
    const [activeBlog, setActiveBlog] = useState(0);

    const { width } = useWindowSize();

    const handleLeftArrowClick = () => {
        if (activeBlog === 0) return;
        setActiveBlog((prevState) => prevState - 1);
    };

    const handleRightArrowClick = () => {
        if (activeBlog === 2) return;
        setActiveBlog((prevState) => prevState + 1);
    };

    return (
        <div className="xl:w-[90%] bg-tertiary flex flex-col mt-24 xl:mt-0">
            {blogCardContents.slice(0, 3).map(
                (card, idx) =>
                    idx === activeBlog && (
                        <div
                            key={card.slug}
                            className="relative w-full h-[120%] flex flex-col justify-start items-center gap-4 -mt-24 px-8 sm:px-16 pb-8"
                        >
                            <motion.div
                                className="w-full md:w-2/3 h-[15em] bg-cover bg-no-repeat bg-center"
                                style={{
                                    backgroundImage: `url(${card.topImage})`,
                                }}
                                initial="hidden"
                                whileInView="visible"
                                variants={fadeIn(1)}
                                viewport={{ once: true }}
                            />
                            <div className="flex flex-row gap-2">
                                {[0, 1, 2].map((index) => (
                                    <div
                                        key={index}
                                        className={`bg-funky h-4 w-4 ${
                                            activeBlog === index && 'w-6'
                                        } rounded-full cursor-pointer`}
                                        onClick={() => setActiveBlog(index)}
                                    />
                                ))}
                            </div>
                            <div className="flex flex-col grow justify-between gap-4 items-center text-dark pt-4">
                                <motion.div
                                    className="flex flex-col items-center gap-4"
                                    initial="hidden"
                                    animate="visible"
                                    variants={containerVariants}
                                >
                                    <motion.h3
                                        className="eagle text-2xl lg:text-3xl text-center"
                                        variants={itemVariants}
                                    >
                                        {card.title}
                                    </motion.h3>
                                    <motion.p
                                        className="md:text-xl"
                                        variants={itemVariants}
                                    >
                                        {card.excerpt}
                                    </motion.p>
                                </motion.div>
                                <Link
                                    to={`blog/${card.slug}`}
                                    className={`w-60 md:text-xl font-semibold text-center ${
                                        BUTTON_COLOR_VARIANTS[Color.FUNKY]
                                    } px-6 py-2`}
                                >
                                    Čítaj viac
                                </Link>
                            </div>
                            {width && width > 640 &&
                                <>
                                    <div
                                        className="absolute left-4 top-[58%] cursor-pointer"
                                        onClick={handleLeftArrowClick}
                                    >
                                        <LeftArrow />
                                    </div>
                                    <div
                                        className="absolute right-4 top-[58%] cursor-pointer"
                                        onClick={handleRightArrowClick}
                                    >
                                        <RightArrow />
                                    </div>
                                </>
                            }
                        </div>
                    )
            )}
        </div>
    );
};
export { BlogCard };
