type Props = {
    value: string;
    classNames?: string;
};

const Heading = ({ value, classNames }: Props) => {
    return (
        <div className="flex flex-row items-end gap-12">
            <h2 className={`eagle text-6xl lg:text-9xl ${classNames}`}>{value}</h2>
        </div>
    );
};
export { Heading };
