import { motion } from 'framer-motion';

type Props = {
    imgSrc: string;
};

const NavImage = ({ imgSrc }: Props) => {
    return (
        <motion.div
            className={`w-full h-full flex flex-row items-center justify-center`}
            initial={{ y: '120%' }}
            animate={{ y: 0 }}
            transition={{
                duration: 1.2,
                type: 'tween',
                ease: 'easeIn',
                delay: 0.5,
            }}
            exit={{ opacity: 0, transition: { delay: 0.2, duration: 0.2 } }}
        >
            <img
                className="w-[70%] aspect-auto"
                src={imgSrc}
                alt="Active"
            />
        </motion.div>
    );
};

export { NavImage };
