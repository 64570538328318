import TopImage from '../assets/TCMImages/topimage.jpg';
import HerbalMedicine from '../assets/TCMImages/top-image.jpg';
import Dietetics from '../assets/TCMImages/dietetika.jpg';
import Acupuncture from '../assets/TCMImages/akupunktura.jpg';
import Cupping from '../assets/TCMImages/bankovanie.jpg';
import { AboutTCMContentItem, Color, Photo } from '../types/types';

const TCMHeroImage: Photo = {
    src: TopImage,
    alt: "O TČM",
};

const TCMintroText: string[] = [
    'Tradičná čínska medicína (TČM) je jedným z najstarších systémov medicíny a liečiteľstva, ktorá sa nepretržite používa zhruba 3500 rokov. Vďaka svojim overeným diagnostickým metódam TČM vyniká v prevencii vzniku chorôb a ich komplikácií.',
    'Taktiež úspešne lieči väčšinu akútnych i chronických ochorení dnešnej doby. TČM vníma človeka ako celok a po odstránení akútnych symptómov sa liečba zameriava na koreň problému, aby sa choroba už nevracala.',
];

const traditionalMedicineText: string[] = [
    'Tradičná medicína a jej vplyv na zdravie človeka sa v posledných rokoch stala predmetom vedeckého skúmania, ako aj snáh o začlenenie do západného systému zdravotníctva. Kým v čínskych nemocniciach je TČM bežne praktizovaná ako integrálna súčasť lekárskych postupov, v Európe sme opatrnejší.',
    'V súčasnosti sa však akupunktúra a liečivé čínske byliny stali aj na západe predmetom mnohých vedeckých štúdií, ktoré sa ich snažia spoznať a začleniť do praxe západnej medicíny.',
    'WHO, ako uvádza na svojej webovej stránke, hrá dôležitú úlohu pri podpore členských štátov v integrovaní tradičnej medicíny do národných zdravotníckych systémov pri zabezpečení kvality, bezpečnosti a efektivity poskytovaných služieb a produktov.',
];

const TCMMainContent: AboutTCMContentItem[] = [
    {
        color: Color.SECONDARY,
        title: 'Dietetika',
        image: Dietetics,
        content: [
            'Zdravie a dĺžka života sú podmienené nielen zdedenými kvalitami, ale taktiež spôsobom, ako sa o seba a svoju kondíciu staráme cvičením, myslením a stravou.',
            'V čínskej dietetike nepočítame kalórie, ale zisťujeme vplyv jedál na organizmus, ako sa cítime po jedle, či je nám horúco alebo zima, či sa cítime ľahko alebo ťažko, či jedlo nenadúva, či spôsobuje hnačky alebo zápchu, atď.',
            'Taktiež využívame poznatky tepelných účinkov potravín. Jedálniček upravujeme podľa ročných období, napríklad na jar sa odporúča konzumovať kyslé potraviny, kým na jeseň pikantné.',
            'Stravovanie nastavujeme tak, aby bolo v súlade so súčasným zdravotným stavom a navrhovanou liečbou.'
        ],
    },
    {
        color: Color.TERTIARY_DARK,
        title: 'Fytoterapia',
        image: HerbalMedicine,
        content: [
            'Čínska tradičná medicína má stáročiami overené pravidlá, aké kombinácie bylín a množstvo treba zvoliť pre rôzne zdravotné účely. Kým niektoré byliny zvyšujú efekt liečby, iné sú pridávané pre odstránenie vedľajších účinkov hlavných prísad. Fytoterapia sa používa vo väčšine liečebných procesov.',
            'Zmes bylín stanovíme na základe diagnostiky. Bylinné zmesi odporúčame na predpísaný počet dní až do nasledujúcej kontroly, kedy sa zmes ďalej upraví podľa aktuálnych potrieb.',
            'Na bylinnú liečbu sa najčastejšie používajú tablety a odvary. Pre deti sa môže použiť granulát, ktorý sa rozpustí v pohári vody.',
        ],
    },
    {
        color: Color.SECONDARY,
        title: 'Akupunktúra',
        image: Acupuncture,
        content: [
            'Čínska medicína pracuje s energiou v tele Qi (čchi). Vpichovaním akupunktúrnych ihličiek do bodov na dráhach sa snaží ovplyvniť tok energie v tele, cirkuláciu krvi a tak znížiť alebo úplne odstrániť akútne, ako aj dlhodobé pocity bolesti.',
            'Akupunktúra má okrem liečenia aktuálnej bolesti aj preventívny účinok.',
        ],
    },
    {
        color: Color.TERTIARY_DARK,
        title: 'Bankovanie a moxovanie',
        image: Cupping,
        content: [
            'Bankovanie odstraňuje bolesti chrbta, stuhnutosť svalov, detoxikuje a súčasne priaznivo ovplyvňuje funkciu vnútorných orgánov.',
            'Pri bankovaní sa v poháriku vytvorí podtlak, a tak sa dovnútra nasaje časť kože aj s podkožím.',
            'Moxovanie je nahrievanie jednotlivých akupunktúrnych bodov, alebo väčších plôch tela (najčastejšie krížov, brucha a podbruška) prostredníctvom cigary z paliny.',
            'Pri oboch procedúrach sa v tkanivách zvyšuje cirkulácia krvi a energie a tým dochádza k liečbe a odstraňovaniu bolesti.'
        ],
    },
]

export { TCMHeroImage, TCMintroText, traditionalMedicineText, TCMMainContent }