import { useState, useEffect, useRef, useMemo } from 'react';
import YinYangHeroImage from '../../assets/LandingPageImages/yinyang-hero.jpg';

type YinYangHeroProperties = {
    width: number;
    borderRadius: number;
};

const YinYangHero = () => {
    const [yinYangProperties, setYinYangProperties] =
        useState<YinYangHeroProperties>({
            width: 60,
            borderRadius: 50,
        });

    const yinYangRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const initialY = yinYangRef.current?.getBoundingClientRect().y;
        const updateSpoonProperties = () => {
            if (yinYangRef.current) {
                const { top } = yinYangRef.current.getBoundingClientRect();
                setYinYangProperties(generateYinYangProperties(top, initialY!));
            }
        };
        document.addEventListener('scroll', updateSpoonProperties);

        return () =>
            document.removeEventListener('scroll', updateSpoonProperties);
    }, []);

    const style = useMemo(() => {
        return {
            marginInline: 'auto',
            borderRadius: `${yinYangProperties.borderRadius}%`,
            width: `${yinYangProperties.width}%`,
        };
    }, [yinYangProperties]);

    return (
        <img
            ref={yinYangRef}
            src={YinYangHeroImage}
            alt="Spoons"
            style={style}
        />
    );
};

function generateYinYangProperties(scrollProgress: number, y: number) {
    if (scrollProgress > 0 && scrollProgress <= y) {
        return {
            width: ((y - scrollProgress) / y) * 40 + 60,
            borderRadius: (scrollProgress / y) * 50,
        };
    }
    return {
        width: 100,
        borderRadius: 0,
    };
}

export { YinYangHero };
