import { TextColor } from "../../types/types";
import { ViewTitle } from "./ViewTitle";

type Props = {
    title: string;
    titleColor: TextColor;
}

const HeaderSection = ({ title, titleColor }: Props) => {
    return <section className="w-full flex flex-col items-center gap-20 pt-40 pb-20 lg:pb-40 px-8 md:px-20 ">
        <ViewTitle title={title} color={titleColor} />
    </section>;
};
export { HeaderSection };
