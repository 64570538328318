import { Category } from '../../types/types';

type Props = {
    categories: Category[];
    activeCategory: string;
    numOfBlogs: number;
    renderAllPosts: () => void;
    onCategoryClick: (category: string) => void;
};

const CategoriesCard = ({
    categories,
    activeCategory,
    numOfBlogs,
    renderAllPosts,
    onCategoryClick,
}: Props) => {
    return (
        <div className="bg-tertiary text-dark flex flex-col gap-4 p-8">
            <h3 className="eagle text-xl md:text-3xl">Kategórie</h3>
            <ul className="flex flex-col gap-1">
                <li
                    className={`md:text-xl cursor-pointer ${
                        activeCategory === '' ? 'text-funky font-bold' : null
                    }`}
                    onClick={renderAllPosts}
                >
                    všetky ({numOfBlogs})
                </li>
                {categories.map((category) => (
                    <li
                        key={category.name}
                        className={`md:text-xl cursor-pointer ${
                            activeCategory === category.name
                                ? 'text-funky font-bold'
                                : null
                        }`}
                        onClick={() => onCategoryClick(category.name)}
                    >
                        {category.name} ({category.amount})
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default CategoriesCard;
