import { useState } from 'react';
import { motion } from 'framer-motion';
import { ExitBtn } from '../shared/SVGs';
import { NavImage } from './NavImage';
import { NavText } from './NavText';
import BlogPhoto from '../../assets/NavImages/nav-blog-photo.jpg';
import AboutTCMPhoto from '../../assets/NavImages/nav-about-tcm-photo.jpg';
import KatikaPhoto from '../../assets/NavImages/katika.jpg';
import PricesPhoto from '../../assets/NavImages/nav-prices-photo.jpg';
import AboutMePhoto from '../../assets/NavImages/nav-online-process-2.jpg';

const NAV_ITEMS = [
    {
        title: 'O FYT SPIRIT',
        url: 'o-fyt-spirit',
        imgSrc: AboutMePhoto,
    },
    {
        title: 'Cenník',
        url: 'o-fyt-spirit-cennik',
        imgSrc: PricesPhoto,
    },
    {
        title: 'O tradičnej čínskej medicíne',
        url: 'o-tcm',
        imgSrc: AboutTCMPhoto,
    },
    {
        title: 'O mne',
        url: 'o-mne',
        imgSrc: KatikaPhoto,
    },
    {
        title: 'Blog',
        url: 'blog',
        imgSrc: BlogPhoto,
    },
];

type Props = {
    onExitClick: () => void;
};

const Navigation = ({ onExitClick }: Props) => {
    const [activeImgSrc, setActiveImgSrc] = useState(AboutMePhoto);

    return (
        <motion.div
            key="navigation"
            className="w-full h-screen bg-secondary grid grid-cols-1 lg:grid-cols-5 absolute top-0 left-0 overflow-hidden z-10"
            initial={{ x: '-100%' }}
            animate={{ x: 0 }}
            transition={{ duration: .75 }}
            exit={{
                x: '-100%',
                transition: {
                    type: 'tween',
                    delay: 0.4,
                    duration: 0.6,
                    ease: 'easeOut',
                },
            }}
        >
            <div className="h-screen flex flex-row justify-center items-center max-[1024px]:hidden lg:col-span-2">
                <NavImage imgSrc={activeImgSrc} />
            </div>
            <ul className="h-screen col-span-1 lg:col-span-3 flex flex-col justify-center">
                {NAV_ITEMS.map((item, idx) => (
                    <NavText
                        key={item.url}
                        url={item.url}
                        index={idx + 1}
                        title={item.title}
                        delay={idx * 0.1 + 1}
                        onHover={() => setActiveImgSrc(item.imgSrc)}
                    />
                ))}
            </ul>
            <ExitBtn onExitClick={onExitClick} />
        </motion.div>
    );
};
export { Navigation };
