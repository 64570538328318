import { useLoaderData } from "react-router-dom";
import { useState } from "react";
import { v4 as uuid } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { MenuButton } from "../components/Navigation/MenuButton";
import { Navigation } from "../components/Navigation/Navigation";
import {
    Color,
    Background,
    TextColor,
    BlogPostType,
    BlogContentObj,
    BlogContentType,
} from "../types/types";
import { HeaderSection } from "../components/shared/HeaderSection";
import { ActionButton } from "../components/shared/ActionButton";
import { useScrollToTop } from "../hooks/useScrollToTop";
import { Logo, ScrollToTop } from "../components/shared/SVGs";
import { Helmet } from "react-helmet";
import { ContactSection } from "../components/shared/ContactSection";

const BlogPost = () => {
    const POST = useLoaderData() as BlogPostType;
    console.log(POST);
    const [showNavigation, setShowNavigation] = useState(false);

    useScrollToTop();

    const renderContent = (content: BlogContentObj) => {
        switch (content.type) {
            case BlogContentType.PARAGRAPH:
                return <p className="text-justify">{content.content}</p>;
            case BlogContentType.SUBTITLE:
                return <h5 className="font-bold">{content.content}</h5>;
            case BlogContentType.IMAGE:
                return (
                    typeof content.content === "string" && (
                        <div className="w-full">
                            <img
                                className="object-contain mx-auto my-6"
                                src={content.content}
                                alt="obrázok"
                            />
                        </div>
                    )
                );
            case BlogContentType.LIST:
                return (
                    typeof content.content !== "string" && (
                        <ul>
                            {content.content.map((item) => (
                                <li
                                    key={uuid()}
                                    className="list-disc ml-8">
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )
                );
            default:
                return;
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    name="theme-color"
                    content="#000000"
                />
                <title>FYT SPIRIT | {POST.title}</title>
            </Helmet>
            <div className="w-full overflow-x-hidden">
                <AnimatePresence>
                    {showNavigation && (
                        <Navigation
                            key={uuid()}
                            onExitClick={() => setShowNavigation(false)}
                        />
                    )}
                    {!showNavigation && (
                        <motion.main
                            className="bg-secondary"
                            key={uuid()}
                            exit={{
                                x: "100%",
                                transition: {
                                    type: "tween",
                                    delay: 0.9,
                                    duration: 0.1,
                                },
                            }}>
                            <div className="px-8 md:px-20 pb-20">
                                <MenuButton
                                    color={Color.TERTIARY_DARK}
                                    lines={Background.SECONDARY}
                                    onClick={() => setShowNavigation(true)}
                                />
                                <HeaderSection
                                    title="Blog"
                                    titleColor={TextColor.TERTIARY}
                                />
                                <article className="mx-auto w-full xl:w-2/3 bg-tertiary px-8 py-12 flex flex-col gap-8">
                                    <h2 className="eagle text-center text-3xl md:text-5xl text-dark">
                                        {POST.title}
                                    </h2>
                                    <h4 className="text-xl md:text-2xl text-center text-dark">
                                        {POST.lede}
                                    </h4>
                                    <img
                                        src={POST.topImage}
                                        alt=""
                                    />
                                    <div className="flex flex-col gap-4 md:text-xl">
                                        {POST.content.map((item) => {
                                            return (
                                                <div key={uuid()}>
                                                    {renderContent(item)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="flex flex-row justify-end mt-4">
                                        <ActionButton
                                            url="/blog"
                                            text="Späť k blogom"
                                            color={Color.FUNKY}
                                        />
                                    </div>
                                </article>
                            </div>
                            <ContactSection color={Color.DARK} />
                            <ScrollToTop />
                        </motion.main>
                    )}
                    <Logo />
                </AnimatePresence>
            </div>
        </>
    );
};

export { BlogPost };
