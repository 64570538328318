type Props = {
    value: string;
};

const SectionHeading = ({ value }: Props) => {
    return (
        <div className="flex flex-row items-end gap-12">
            <h2 className={`eagle text-3xl lg:text-5xl`}>
                {value}
            </h2>
        </div>
    );
};
export { SectionHeading };
