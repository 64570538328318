import { useEffect, useRef, useState } from 'react';

type Props = {
    image: string;
    setHeroAnimationFinished: React.Dispatch<React.SetStateAction<boolean>>;
};

const AboutTCMHero = ({ image, setHeroAnimationFinished }: Props) => {
    const [heroWidth, setHeroWidth] = useState(100);

    const heroRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const updateHeroWidth = () => {
            if (heroRef.current) {
                const { top, bottom } = heroRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight;
                if (top < windowHeight - 200 && bottom >= 300) {
                    const percentageProgress = Math.min(
                        (windowHeight - top - 200) / (windowHeight - 500),
                        1
                    );
                    setHeroWidth(100 - percentageProgress * 40);
                }
            }
        };

        if (window.innerWidth >= 1280) {
            document.addEventListener('scroll', updateHeroWidth);
        }

        return () => {
            if (window.innerWidth >= 1280) {
                document.removeEventListener('scroll', updateHeroWidth);
            }
        };
    }, [heroRef]);

    useEffect(() => {
        if (heroWidth === 60 || window.innerWidth < 1280) {
            setHeroAnimationFinished(true);
        } else setHeroAnimationFinished(false);
    }, [heroWidth, setHeroAnimationFinished]);

    return (
        <img
            ref={heroRef}
            src={image}
            alt="tcm"
            style={{ width: `${heroWidth}%`, height: 'auto' }}
        />
    );
};

export { AboutTCMHero };
