import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { MenuButton } from "../components/Navigation/MenuButton";
import { Navigation } from "../components/Navigation/Navigation";
import {
    Background,
    Color,
    TextColor,
    BlogPostType,
    RecommendedCardPropType,
} from "../types/types";
import { HeaderSection } from "../components/shared/HeaderSection";
import { sortedBlogPosts as blogPosts, categories } from "../data/BlogPageData";
import { BlogCard } from "../components/Blog/BlogCard";
import CategoriesCard from "../components/Blog/CategoriesCard";
import { RecommendedCard } from "../components/Blog/RecommendedCard";
import { SearchBar } from "../components/Blog/SearchBar";
import { Logo, ScrollToTop } from "../components/shared/SVGs";
import { Helmet } from "react-helmet";
import { ContactSection } from "../components/shared/ContactSection";
import { useScrollToTop } from "../hooks/useScrollToTop";

const Blog = () => {
    const [showNavigation, setShowNavigation] = useState(false);
    const [activeCategory, setActiveCategory] = useState("");
    const [renderedBlogPosts, setRenderedBlogPosts] = useState(blogPosts);

    useScrollToTop([renderedBlogPosts]);

    const renderAllPosts = useCallback(() => {
        setRenderedBlogPosts(blogPosts);
    }, []);

    const filterBlogPostsByCategory = useCallback(
        (category: string): BlogPostType[] => {
            return activeCategory
                ? blogPosts.filter((post) => post.categories.includes(category))
                : blogPosts;
        },
        [activeCategory]
    );

    const containsKeyword = useCallback(
        (post: BlogPostType, keyword: string) => {
            for (let i = 0; i < post.content.length; i++) {
                if (
                    post.content[i].content.includes(keyword) ||
                    post.title.includes(keyword)
                ) {
                    return true;
                } else return false;
            }
        },
        []
    );

    const filterBlogPostsByKeyword = useCallback(
        (keyword: string) => {
            setRenderedBlogPosts(
                blogPosts.filter((post) => containsKeyword(post, keyword))
            );
        },
        [containsKeyword]
    );

    const recommended = useMemo((): RecommendedCardPropType[] => {
        const result: RecommendedCardPropType[] = [];
        blogPosts.forEach((post) => {
            if (post.isRecommended) {
                result.push({ title: post.title, slug: post.slug });
            }
        });
        return result;
    }, []);

    useEffect(() => {
        setRenderedBlogPosts(filterBlogPostsByCategory(activeCategory));
    }, [activeCategory, filterBlogPostsByCategory]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    name="theme-color"
                    content="#000000"
                />
                <meta
                    name="description"
                    content="FYT SPIRIT ponúka online diagnostiku tradičnej čínskej medicíny (TČM) z pohodlia vášho domova. Získajte individuálny bylinný recept a dietetické poradenstvo pre zlepšenie fyzického a duševného zdravia."
                />
                <meta
                    name="keywords"
                    content="FYT SPIRIT, tradičná čínska medicína, TČM, fytoterapia, diagnostika, online, zdravie, duševné zdravie, fyzické zdravie, bylinná liečba, dietetické poradenstvo, prevencia, choroby"
                />
                <meta
                    name="author"
                    content="Katarína Nagyová Šnircová"
                />
                <meta
                    property="og:title"
                    content="FYT SPIRIT | Blog"
                />
                <meta
                    property="og:description"
                    content="FYT SPIRIT ponúka online diagnostiku tradičnej čínskej medicíny (TČM) z pohodlia vášho domova. Získajte individuálny bylinný recept a dietetické poradenstvo pre zlepšenie fyzického a duševného zdravia."
                />
                <title>FYT SPIRIT | Blog</title>
            </Helmet>
            <div className="w-full">
                <AnimatePresence>
                    {showNavigation && (
                        <Navigation
                            key={uuid()}
                            onExitClick={() => setShowNavigation(false)}
                        />
                    )}
                    {!showNavigation && (
                        <motion.main
                            key={uuid()}
                            className="bg-secondary"
                            // exit={{
                            //     x: '100%',
                            //     transition: {
                            //         type: 'tween',
                            //         delay: 0.9,
                            //         duration: 0.1,
                            //     },
                            // }}
                        >
                            <div className="px-8 md:px-20 pb-20">
                                <MenuButton
                                    color={Color.TERTIARY_DARK}
                                    lines={Background.SECONDARY}
                                    onClick={() => setShowNavigation(true)}
                                />
                                <HeaderSection
                                    title="Blog"
                                    titleColor={TextColor.TERTIARY}
                                />
                                <div
                                    className={`grid grid-cols-1 lg:grid-cols-3 gap-8`}>
                                    <section className="col-span-1 lg:col-span-2 flex flex-col gap-8">
                                        {renderedBlogPosts.map((post) => (
                                            <BlogCard
                                                data={post}
                                                key={post.slug}
                                            />
                                        ))}
                                    </section>
                                    <section className="col-span-1 lg:justify-self-end lg:w-[80%] flex flex-col gap-8">
                                        <SearchBar
                                            onSearchClick={
                                                filterBlogPostsByKeyword
                                            }
                                        />
                                        <CategoriesCard
                                            categories={categories}
                                            activeCategory={activeCategory}
                                            numOfBlogs={blogPosts.length}
                                            renderAllPosts={renderAllPosts}
                                            onCategoryClick={(
                                                category: string
                                            ) => setActiveCategory(category)}
                                        />
                                        <RecommendedCard
                                            recommendedList={recommended}
                                        />
                                    </section>
                                </div>
                            </div>
                            <ContactSection color={Color.DARK} />
                            <ScrollToTop />
                        </motion.main>
                    )}
                    <Logo />
                </AnimatePresence>
            </div>
        </>
    );
};

export { Blog };
