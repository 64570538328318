import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { HeaderSection } from '../components/shared/HeaderSection';
import { MenuButton } from '../components/Navigation/MenuButton';
import { Navigation } from '../components/Navigation/Navigation';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { Color, Background, TextColor } from '../types/types';
import {
    TCMHeroImage,
    TCMMainContent,
    TCMintroText,
    traditionalMedicineText,
} from '../data/AboutTCMData';
import { IntroCard } from '../components/shared/IntroCard';
import { AboutTCMSection } from '../components/AboutTCM/AboutTCMSection';
import { Logo, ScrollToTop } from '../components/shared/SVGs';
import { HeroImage } from '../components/shared/HeroImage';
import { Helmet } from 'react-helmet';
import { ContactSection } from '../components/shared/ContactSection';

const AboutTCM = () => {
    const [showNavigation, setShowNavigation] = useState(false);

    useScrollToTop();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="Stránka FYT SPIRIT o tradičnej čínskej medicíne (TČM), ktorá sa zaoberá diagnostickými metódami, prevenciou chorôb, akupunktúrou, liečivými čínskymi bylinami, diétetikou, fytoterapiou, bankovaním a moxovaním."
                />
                <meta
                    name="keywords"
                    content="tradičná čínska medicína, TČM, diagnostické metódy, prevencia chorôb, akupunktúra, liečivé čínske byliny, diétetika, fytoterapia, bankovanie, moxovanie"
                />
                <meta
                    property="og:title"
                    content="FYT SPIRIT | O tradičnej čínskej medicíne"
                />
                <meta
                    property="og:description"
                    content="Stránka FYT SPIRIT o tradičnej čínskej medicíne (TČM), ktorá sa zaoberá diagnostickými metódami, prevenciou chorôb, akupunktúrou, liečivými čínskymi bylinami, diétetikou, fytoterapiou, bankovaním a moxovaním."
                />

                <meta name="author" content="Katarína Nagyová Šnircová" />
                <title>FYT SPIRIT | O tradičnej čínskej medicíne</title>
            </Helmet>
            <div className="w-full">
                <AnimatePresence>
                    {showNavigation && (
                        <Navigation
                            key={uuid()}
                            onExitClick={() => setShowNavigation(false)}
                        />
                    )}
                    {!showNavigation && (
                        <motion.main
                            className="bg-primary"
                            key={uuid()}
                            exit={{
                                x: '100%',
                                transition: {
                                    type: 'tween',
                                    delay: 0.9,
                                    duration: 0.1,
                                },
                            }}
                        >
                            <div className="px-8 md:px-20 pb-20">
                                <MenuButton
                                    color={Color.TERTIARY_LIGHT}
                                    lines={Background.PRIMARY}
                                    onClick={() => setShowNavigation(true)}
                                />
                                <HeaderSection
                                    title="O tradičnej čínskej medicíne"
                                    titleColor={TextColor.TERTIARY}
                                />
                                <div className="flex flex-col gap-16">
                                    <HeroImage photo={TCMHeroImage} />
                                    <IntroCard
                                        topText={TCMintroText}
                                        bottomText={traditionalMedicineText}
                                        color={TextColor.SECONDARY}
                                        addCalendly
                                    />
                                    {TCMMainContent.map((item, idx) => (
                                        <AboutTCMSection
                                            key={uuid()}
                                            id={idx.toString()}
                                            content={item}
                                        />
                                    ))}
                                </div>
                            </div>
                            <ContactSection color={Color.DARK} />
                            <ScrollToTop />
                        </motion.main>
                    )}
                    <Logo />
                </AnimatePresence>
            </div>
        </>
    );
};
export { AboutTCM };
