import { PopupButton } from 'react-calendly';

const CalendlyButton = () => {
    const root: HTMLElement = document.getElementById('root') as HTMLElement;
    return (
        <PopupButton
            url="https://calendly.com/fytspirit/vstupna_diagnostika"
            text="Objednaj sa"
            rootElement={root}
            className='text-base md:text-2xl font-semibold bg-funky text-tertiary hover:bg-tertiary hover:text-funky border-2 hover:border-2 hover:border-funky hover:transition-colors duration-700 px-3 sm:px-6 py-4 h-auto'
        />
    );
};

export { CalendlyButton };
