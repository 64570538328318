import { motion } from 'framer-motion';
import { therapySectionData } from '../../data/LandingPageData';
import { SectionProps, SECTION_COLOR_VARIANTS, Color, Direction } from '../../types/types';
import { Heading } from '../shared/Heading';
import { Section } from '../shared/Section';
import { ActionButton } from '../shared/ActionButton';
import { fadeIn, slideIn } from '../../animations/animations';
import { CalendlyButton } from '../shared/Calendly';
import { FYTSpiritHeroImage } from '../../data/AboutFYTSpiritData';

const AboutFYTSpiritSection = ({ color }: SectionProps) => {
    return (
        <Section id="01" classNames={`${SECTION_COLOR_VARIANTS[color]} whitesection`}>
            <Heading value={therapySectionData.title} />
            <motion.div className="flex flex-col-reverse xl:flex-row gap-8 lg:gap-40">
                <div className="xl:w-1/2 flex flex-col justify-between gap-16">
                    <article className="flex flex-col gap-8">
                        {therapySectionData.paragraphs.map((p, idx) => (
                            <p key={idx} className="md:text-3xl">
                                {p}
                            </p>
                        ))}
                    </article>
                    <div className="flex flex-row gap-2 xl:gap-20 justify-between xl:justify-start">
                        <ActionButton
                            color={Color.SECONDARY}
                            text="Viac o FYT SPIRIT"
                            url="o-fyt-spirit"
                            variants={slideIn(Direction.LEFT, 1, 0.4)}
                        />
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            variants={slideIn(Direction.RIGHT, 1, 0.4)}
                            viewport={{ once: true }}
                        >
                            <CalendlyButton />
                        </motion.div>
                    </div>
                </div>
                <div className="xl:w-1/2 flex flex-row justify-center">
                    <motion.img
                        className='object-contain'
                        src={FYTSpiritHeroImage.src}
                        alt={FYTSpiritHeroImage.alt}
                        initial="hidden"
                        whileInView="visible"
                        variants={fadeIn(1.5)}
                        viewport={{ once: true }}
                    />
                </div>
            </motion.div>
        </Section>
    );
};
export { AboutFYTSpiritSection };
