import { v4 as uuid } from "uuid";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { timelineContent } from "../../data/AboutMePageData";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useMemo } from "react";

const Timeline = () => {
    const { width } = useWindowSize();

    const shouldAnimate = useMemo(() => {
        if (width) {
            return width > 640;
        }
    }, [width]);

    return (
        <VerticalTimeline
            lineColor="#FD5458"
            animate={shouldAnimate}>
            {timelineContent.map((item) => (
                <VerticalTimelineElement
                    key={uuid()}
                    className="vertical-timeline-element--education"
                    contentStyle={{
                        background: "#2b7a78",
                        color: "#feffff",
                    }}
                    contentArrowStyle={{
                        borderRight: "7px solid #2b7a78",
                    }}
                    date={item.date}
                    dateClassName="timelinedate"
                    iconStyle={{ background: "#FD5458", color: "#fff" }}>
                    <h3 className="eagle text-lg md:text-2xl">{item.title}</h3>
                    <ul className="mt-4 border-b-2 pb-4">
                        {item.content.map((point) => (
                            <li
                                key={uuid()}
                                className="md:text-xl list-disc ml-8">
                                {point}
                            </li>
                        ))}
                    </ul>
                    <div className="md:text-xl mt-4">{item.footer}</div>
                </VerticalTimelineElement>
            ))}
        </VerticalTimeline>
    );
};
export { Timeline };
