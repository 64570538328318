import { BlogPostType, Color } from "../../types/types";
import { getPrettyDate } from "../../utils/utils";
import { ActionButton } from "../shared/ActionButton";

type Props = {
    data: BlogPostType;
};

const BlogCard = ({
    data: { title, slug, topImage, categories, excerpt, date },
}: Props) => {
    return (
        <div className="w-full bg-tertiary flex flex-col xl:flex-row gap-8 text-funky">
            <div
                className="w-full xl:min-w-[40%] min-h-[300px] bg-cover bg-no-repeat bg-center"
                style={{ backgroundImage: `url(${topImage})` }}
            />
            <div className="flex flex-col grow justify-between xl:py-8 pb-8 xl:pb-8 pl-8 xl:pl-0 pr-8">
                <div className="flex flex-col gap-4 md:text-xl pb-8 text-dark">
                    <h3 className="eagle text-xl md:text-3xl">{title}</h3>
                    <p>{getPrettyDate(date)}</p>
                    <p>{excerpt}</p>
                    <div className="flex flex-row gap-4">
                        {categories.map((category) => (
                            <p key={category}>#{category}</p>
                        ))}
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    <ActionButton
                        text="Čítaj ďalej"
                        url={slug}
                        color={Color.FUNKY}
                    />
                </div>
            </div>
        </div>
    );
};
export { BlogCard };
