import { useState } from 'react';

type Props = {
    letter: string;
};


const MarqueeLetter = ({ letter }: Props) => {
    const [rgb, setRgb] = useState(255);

    const handleColorChange = () => {
        for (let i = 0; i < 256; i++) {
            setTimeout(() => {
                setRgb(i)
            }, i * 15)
        }
    }

    return (
        <span
            style={{ color: `rgb(${rgb}, ${rgb}, ${rgb})` }}
            onMouseEnter={() => setRgb(0)}
            onMouseLeave={handleColorChange}
        >
            {letter}
        </span>
    );
};
export { MarqueeLetter };
