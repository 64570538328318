type Props = {
    children: React.ReactNode;
    classNames: string;
    id: string;
}

const Section = ({ children, classNames, id }: Props) => {
    return <div id={id} className={`relative w-full flex flex-col gap-20 py-20 px-8 md:px-20 ${classNames}`}>{children}</div>;
};

export { Section };
