import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createHashRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    HashRouter,
    Routes
} from 'react-router-dom';
import './styles/index.css';
import { LandingPage } from './views/LandingPage';
import { AboutMe } from './views/AboutMe';
import { AboutTCM } from './views/AboutTCM';
import { AboutFYTSpirit } from './views/AboutFYTSpirit';
import { Blog } from './views/Blog';
import { BlogPost } from './views/BlogPost';
import { sortedBlogPosts as blogPosts } from './data/BlogPageData';
import { fetchPost } from './utils/utils';


const router = createHashRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<LandingPage />} />
            <Route path="o-mne" element={<AboutMe />} />
            <Route path="o-tcm" element={<AboutTCM />} />
            <Route path="o-fyt-spirit" element={<AboutFYTSpirit />} />
            <Route path="o-fyt-spirit-cennik" element={<AboutFYTSpirit scrollToPrices />} />
            <Route path="blog" element={<Blog />} />
            <Route
                path="blog/:title"
                element={<BlogPost />}
                errorElement={<Blog />}
                loader={({ params }) =>
                    params.title && fetchPost(params.title, blogPosts)
                }
            />
        </>
    )
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
