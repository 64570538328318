import { BlogCardContent } from '../types/types'
import Katika from '../assets/AboutFYTSpiritImages/hero-image-katika.jpg'
import TCMHeroImage from '../assets/LandingPageImages/tcm1.jpg'
import Logo from '../assets/LandingPageImages/contact-logo.jpg'

enum Section {
    ABOUT_ME = 'O mne',
    ABOUT_TCM = 'O TČM',
    THERAPY = 'O FYT SPIRIT',
    BLOG = 'Blog',
    CONTACT = 'Kontakt'
}

const scrollHandlerData = [
    {
        index: '01',
        text: Section.THERAPY
    },
    {
        index: '02',
        text: Section.ABOUT_TCM
    },

    {
        index: '03',
        text: Section.ABOUT_ME
    },

    {
        index: '04',
        text: Section.BLOG
    },

    {
        index: '05',
        text: Section.CONTACT
    },
]

const headingSectionData = {
    pageTitle: {
        FYT: 'FYT',
        SPIRIT: 'SPIRIT'
    },
    tagline: [
        'Tradičná čínska medicína',
        'z pohodlia vášho domova'
    ],
    elevatorPitch: [
        'Verím krédu „v zdravom tele zdravý duch“. Fyzické a duševné zdravie sú úzko prepojené ako Yin a Yang. Keď sa naruší jedno, začne sa skôr či neskôr kaziť aj druhé. Aby sme sa mohli skutočne cítiť fit, musíme byť zdraví fyzicky aj psychicky. Hlavným prostriedkom liečby, ktorú ponúkam, je tradičná čínska fytoterapia - bylinná liečba. FYT znamená cítiť sa fit pomocou fytoterapie. SPIRIT poukazuje na našu dušu. Symbol je v kruhu, pretože nemá začiatok ani koniec. Telo aj duša tvoria jeden celok a je dôležité, aby sme sa postarali o jeho obe súčasti.']
}

const aboutMeSectionData = {
    title: Section.ABOUT_ME,
    paragraphs: [
        'Som terapeutka čínskej medicíny s mnohými skúsenosťami, ktoré som získala počas svojej 8-ročnej praxe v Bratislave. Vo svojej ordinácii som pomohla zlepšiť kvalitu fyzického či duševného zdravia niekoľkým stovkám klientov.',
        'V lete 2022 som sa presťahovala do zahraničia a odvtedy komunikujem s klientami online. Ak sa chcete konečne cítiť aj vy fit, neváhajte sa objednať na vstupnú diagnostiku, ktorej súčasťou je zostavenie bylinného receptu na mieru a dietetické poradenstvo.'
    ],
    image: Katika,
}

const aboutTCMSectionData = {
    title: Section.ABOUT_TCM,
    paragraphs: [
        'Tradičná čínska medicína (TČM) je jedným z najstarších systémov medicíny a liečiteľstva, ktorá sa nepretržite používa zhruba 3500 rokov. Vďaka svojim overeným diagnostickým metódam TČM vyniká v prevencii vzniku chorôb a ich komplikácií.',
        'Taktiež úspešne lieči väčšinu akútnych i chronických ochorení dnešnej doby. TČM vníma človeka ako celok a po odstránení akútnych symptómov sa liečba zameriava na koreň problému, aby sa choroba už nevracala.'
    ],
    heroImage: TCMHeroImage,
    marqueeContent: [
        'Fytoterapia',
        'Dietetika',
        'Akupunktúra',
        'Bankovanie',
        'Tuina masáž'
    ]
}

const therapySectionData = {
    title: Section.THERAPY,
    paragraphs: [
        'Prostredníctvom FYT SPIRIT vám prinášam online diagnostiku TČM. Na základe podrobného rozhovoru  a diagnostiky z jazyka dokážem vyhodnotiť energetické nedostatky jednotlivých orgánov, prípadne identifikovať nežiadúce patogény vo vašom tele. Jedna choroba môže mať viacej príčin, a mojou úlohou je nájsť pravého pôvodcu vašich problémov. Odhalenie príčiny ochorenia je kľúčom k nasadeniu úspešnej liečby.',
        'Hlavným nástrojom liečby je fytoterapia (bylinná liečba). Pre každého klienta zostavujem individuálny recept tak, aby dostal telo aj myseľ do čo najväčšej rovnováhy. Pri chronických problémoch pracujem s klientmi dlhodobo, a stretávam sa s nimi v online svete pravidelne, až kým to ich stav vyžaduje. Verím, že každý, kto sa necíti fit, sa môže cítiť lepšie. Len treba pre to niečo urobiť.'
    ]
}

type BlogSectionData = {
    title: Section,
    paragraphs: string[],
    blogCardContents: BlogCardContent[]
}

const blogSectionData: BlogSectionData = {
    title: Section.BLOG,
    paragraphs: [
        'V blogoch nájdete podrobnejšie vysvetlené niektoré bežné problémy z hľadiska TČM. Dozviete sa napríklad, prečo je ťažšie otehotnieť po 30tke, aké rôzne môžu byť príčiny nafukovania, čo s opakovanými zápalmi močových ciest, kedy použiť surový a kedy radšej sušený zázvor, ale aj rady ako sa stravovať počas jednotlivých ročných období.',
        'Ak vás tieto témy zaujímajú a chcete sa dozvedieť ešte viac, sledujte FYT SPIRIT aj na Facebooku a Instagrame.'
    ],
    blogCardContents: [
        {
            title: 'Heading 1',
            excerpt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt magni sint tempore, quae odio pariatur, a voluptatibus in consequuntur, fuga perferendis eum ad delectus! Quidem temporibus odit atque asperiores sunt?',
            thumbnail: TCMHeroImage,
            url: ''
        },
        {
            title: 'Heading 2',
            excerpt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt magni sint tempore, quae odio pariatur, a voluptatibus in consequuntur, fuga perferendis eum ad delectus! Quidem temporibus odit atque asperiores sunt?',
            thumbnail: Katika,
            url: ''
        },
        {
            title: 'Heading 3',
            excerpt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt magni sint tempore, quae odio pariatur, a voluptatibus in consequuntur, fuga perferendis eum ad delectus! Quidem temporibus odit atque asperiores sunt? Quidem temporibus odit atque asperiores sunt?',
            thumbnail: TCMHeroImage,
            url: ''
        },
    ],
}

const contactSectionData = {
    title: Section.CONTACT,
    logo: {
        src: Logo,
        alt: 'FYT SPIRIT logo'
    },
    companyInfo: [
        'Mgr. Katarína Nagyová Šnircová',
        'Jána Ondruša 2790/13A',
        '90031, Stupava',
        'IČO: 47103396',
    ],
    contactInfo: [
        'fytspirit@gmail.com',
        '+421 917 108 800'
    ],
    socialURLs: {
        facebook: '/',
        instagram: '/'
    },
    gdpr: {
        text: 'Ochrana osobných údajov',
        url: '/'
    },
}

export {
    scrollHandlerData,
    headingSectionData,
    aboutMeSectionData,
    aboutTCMSectionData,
    therapySectionData,
    blogSectionData,
    contactSectionData,
}