import { AnimatePresence, motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { HeaderSection } from '../components/shared/HeaderSection';
import { MenuButton } from '../components/Navigation/MenuButton';
import { Navigation } from '../components/Navigation/Navigation';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { Color, Background, TextColor } from '../types/types';
import {
    FYTSpiritHeroImage,
    aboutFYTSpiritIntro,
    aboutFYTSpiritFAQ,
    productPrices,
    therapyPrices,
} from '../data/AboutFYTSpiritData';
import { IntroCard } from '../components/shared/IntroCard';
import { Logo, ScrollToTop } from '../components/shared/SVGs';
import { HeroImage } from '../components/shared/HeroImage';
import { Accordion } from '../components/AboutFYTSpirit/Accordion';
import { Helmet } from 'react-helmet';
import { slideInText } from '../animations/animations';
import { CalendlyButton } from '../components/shared/Calendly';
import { ContactSection } from '../components/shared/ContactSection';

type Props = {
    scrollToPrices?: boolean;
};

const AboutFYTSpirit = ({ scrollToPrices }: Props) => {
    const [showNavigation, setShowNavigation] = useState(false);

    const pricesSectionRef = useRef<HTMLElement>(null);

    useScrollToTop();

    useEffect(() => {
        if (scrollToPrices && pricesSectionRef.current) {
            window.scrollTo({
                top: pricesSectionRef.current.getBoundingClientRect().top,
                behavior: 'smooth',
            });
        }
    }, [scrollToPrices, pricesSectionRef]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="O FYT SPIRIT - Online konzultácie a diagnostika s terapeutkou Katarínou Nagyovou Šnircovou. Ponúka tradičnú čínsku medicínu, bylinné produkty a diagnostiku z jazyka."
                />
                <meta
                    name="keywords"
                    content="FYT SPIRIT, Katarína Nagyová Šnircová, čínska medicína, online konzultácie, TČM, tradičná čínska medicína, diagnostika, bylinné produkty, cena, cenník, terapeutka, online diagnostika, diagnostika z jazyka, Bratislava, Slovensko"
                />
                <meta property="og:title" content="FYT SPIRIT | O FYT SPIRIT" />
                <meta
                    property="og:description"
                    content="O FYT SPIRIT - Online konzultácie a diagnostika s terapeutkou Katarínou Nagyovou Šnircovou. Ponúka tradičnú čínsku medicínu, bylinné produkty a diagnostiku z jazyka."
                />
                <meta name="author" content="Katarína Nagyová Šnircová" />
                <title>FYT SPIRIT | O FYT SPIRIT</title>
            </Helmet>
            <AnimatePresence>
                {showNavigation && (
                    <Navigation
                        key={uuid()}
                        onExitClick={() => setShowNavigation(false)}
                    />
                )}
                {!showNavigation && (
                    <motion.main
                        key={uuid()}
                        className="bg-secondary"
                        exit={{
                            x: '100%',
                            transition: {
                                type: 'tween',
                                delay: 0.9,
                                duration: 0.1,
                            },
                        }}
                    >
                        <MenuButton
                            color={Color.TERTIARY_DARK}
                            lines={Background.SECONDARY}
                            onClick={() => setShowNavigation(true)}
                        />
                        <HeaderSection
                            title="O FYT SPIRIT"
                            titleColor={TextColor.TERTIARY}
                        />
                        <div className="px-8 md:px-20 pb-20">
                            <div className="flex flex-col gap-16">
                                <section className="flex flex-col gap-16">
                                    <HeroImage photo={FYTSpiritHeroImage} />
                                    <IntroCard
                                        topText={aboutFYTSpiritIntro.topText}
                                        bottomText={
                                            aboutFYTSpiritIntro.bottomText
                                        }
                                        color={TextColor.SECONDARY}
                                    />
                                    <section
                                        className={`mx-auto w-full xl:w-2/3 bg-tertiary px-8 lg:px-16 py-12 flex flex-col gap-12`}
                                        ref={pricesSectionRef}
                                    >
                                        <article className="flex flex-col gap-8 text-justify sm:text-lg md:text-2xl border-b-2 border-b-primary pb-12">
                                            <h3 className="eagle text-secondary text-3xl text-center">
                                                Cenník
                                            </h3>
                                            <div className="flex flex-col gap-4">
                                                {therapyPrices.map((item) => (
                                                    <div
                                                        key={uuid()}
                                                        className="flex flex-row justify-between items-end gap-8 text-secondary"
                                                    >
                                                        <p>{item.item}</p>
                                                        <p className="min-w-fit">{`${item.price} eur`}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </article>
                                        <article className="flex flex-col gap-4 text-justify text-secondary sm:text-lg md:text-2xl">
                                            <h4 className="font-bold">
                                                {productPrices.heading}
                                            </h4>
                                            <p className="">
                                                {productPrices.content}
                                            </p>
                                        </article>
                                        <CalendlyButton />
                                    </section>
                                </section>
                                <section className="flex flex-col gap-4 mx-auto w-full xl:w-2/3">
                                    <h3 className="eagle flex flex-col gap-8 bg-secondary text-tertiary border-2 border-tertiary text-xl md:text-4xl text-center px-4 md:px-16 py-8">
                                        Často kladené otázky
                                    </h3>
                                    {aboutFYTSpiritFAQ.map((faq, idx) => (
                                        <Accordion
                                            key={uuid()}
                                            faq={faq}
                                            variants={slideInText(idx * 0.1)}
                                        />
                                    ))}
                                </section>
                            </div>
                        </div>
                        <ContactSection color={Color.DARK} />
                        <ScrollToTop />
                    </motion.main>
                )}
                <Logo />
            </AnimatePresence>
        </>
    );
};
export { AboutFYTSpirit };
