import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { AnimatePresence, motion } from 'framer-motion';
import { MenuButton } from '../components/Navigation/MenuButton';
import { Navigation } from '../components/Navigation/Navigation';
import { Background, Color, TextColor } from '../types/types';
import { HeaderSection } from '../components/shared/HeaderSection';
import { AboutMeCard } from '../components/AboutMe/AboutMeCard';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { Timeline } from '../components/AboutMe/Timeline';
import { Logo, ScrollToTop } from '../components/shared/SVGs';
import { Helmet } from 'react-helmet';
import { ContactSection } from '../components/shared/ContactSection';

const AboutMe = () => {
    const [showNavigation, setShowNavigation] = useState(false);

    useScrollToTop();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="Mgr. Katarína Nagyová Šnircová je terapeutka tradičnej čínskej medicíny (TČM) s mnohými skúsenosťami získanými počas 8-ročnej praxe v Bratislave."
                />
                <meta
                    name="keywords"
                    content="Katarína Nagyová Šnircová, tradičná čínska medicína, TČM, terapeutka, fytoterapia, akupunktúra, tui-na masáže, dietetika, západná medicína, bi zheng, atopie, gastroenterológia, ušná akupunktúra, gu zheng, Bachove kvetové esencie, kurz pre duly, rebozo ženská a tehotenská starostlivosť"
                />
                <meta property="og:title" content="FYT SPIRIT | O mne" />
                <meta
                    property="og:description"
                    content="Mgr. Katarína Nagyová Šnircová je terapeutka tradičnej čínskej medicíny (TČM) s mnohými skúsenosťami získanými počas 8-ročnej praxe v Bratislave."
                />
                <meta name="author" content="Katarína Nagyová Šnircová" />
                <title>FYT SPIRIT | O mne - Katarína Nagyová Šnircová</title>
            </Helmet>
            <div className="w-full">
                <AnimatePresence>
                    {showNavigation && (
                        <Navigation
                            key={uuid()}
                            onExitClick={() => setShowNavigation(false)}
                        />
                    )}
                    {!showNavigation && (
                        <motion.main
                            key={uuid()}
                            className="bg-primary"
                            exit={{
                                x: '100%',
                                transition: {
                                    type: 'tween',
                                    delay: 0.9,
                                    duration: 0.1,
                                },
                            }}
                        >
                            <div className="px-8 md:px-20 pb-20">
                                <MenuButton
                                    color={Color.TERTIARY_LIGHT}
                                    lines={Background.PRIMARY}
                                    onClick={() => setShowNavigation(true)}
                                />
                                <HeaderSection
                                    title="O mne"
                                    titleColor={TextColor.TERTIARY}
                                />
                                <AboutMeCard />
                                <section className="w-full bg-tertiary flex flex-col gap-8 pt-16">
                                    <h2 className="eagle text-3xl lg:text-6xl text-center text-secondary">
                                        Moja cesta za vzdelaním
                                    </h2>
                                    <Timeline />
                                </section>
                            </div>
                            <ContactSection color={Color.DARK} />
                            <ScrollToTop />
                        </motion.main>
                    )}
                    <Logo />
                </AnimatePresence>
            </div>
        </>
    );
};
export { AboutMe };
