import { Link } from 'react-router-dom';
import { motion, Variants } from 'framer-motion';
import { Color, BUTTON_COLOR_VARIANTS } from '../../types/types';

type Props = {
    text: string;
    url: string;
    color: Color;
    variants?: Variants;
};

const ActionButton = ({ color, text, url, variants }: Props) => {
    return (
        <motion.button
            initial="hidden"
            whileInView="visible"
            variants={variants}
            viewport={{ once: true }}
        >
            <Link
                className={`text-base md:text-2xl font-semibold ${BUTTON_COLOR_VARIANTS[color]} px-3 sm:px-6 py-4`}
                to={url}
            >
                {text}
            </Link>
        </motion.button>
    );
};
export { ActionButton };
