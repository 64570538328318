import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';
import { useFirstRender } from '../../hooks/useFirstRender';
import { headingSectionData } from '../../data/LandingPageData';
import { slideInText } from '../../animations/animations';
import { YinYangHero } from './YinYangHero';

type Props = {
    isFirstRender: boolean;
};

const HeaderSection = (props: Props) => {
    const isFirstRender = useFirstRender();

    const animateTitle = (delay: number, isFirstRender: boolean) => {
        if (isFirstRender && props.isFirstRender) {
            return {
                hidden: {
                    opacity: 0,
                    x: '200%',
                    y: '-200%',
                },
                show: {
                    opacity: 1,
                    x: 0,
                    y: 0,
                    rotate: [90, 0],
                    transition: {
                        type: 'tween',
                        duration: 2,
                        delay: delay,
                        ease: 'easeOut',
                    },
                },
            };
        }
    };

    return (
        <section className="relative w-full bg-funky flex flex-col items-center gap-20 py-48 px-8 md:px-20 ">
            <div className="flex flex-col items-center gap-6 md:gap-20 lg:gap-6 eagle text-tertiary">
                <motion.h1 className="text-5xl md:text-8xl lg:text-9xl text-center pb-6 flex flex-row justify-center">
                    {headingSectionData.pageTitle.FYT.split('').map(
                        (letter) => (
                            <span className="fyt" key={uuid()}>
                                {letter}
                            </span>
                        )
                    )}
                    <span>&nbsp;</span>
                    {headingSectionData.pageTitle.SPIRIT.split('').map(
                        (letter, idx) => (
                            <motion.div
                                className="fyt"
                                key={uuid()}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true }}
                                variants={animateTitle(
                                    idx * 0.5,
                                    isFirstRender
                                )}
                            >
                                {letter}
                            </motion.div>
                        )
                    )}
                </motion.h1>
                <div className="flex flex-col md:gap-3">
                    {headingSectionData.tagline.map((line) => (
                        <h3 key={uuid()} className="text-xl md:text-4xl">
                            {line}
                        </h3>
                    ))}
                </div>
            </div>
            <div className="w-full">
                <YinYangHero />
            </div>
            <div>
                {headingSectionData.elevatorPitch.map((line, idx) => (
                    <motion.p
                        key={uuid()}
                        className="eagle mx-auto md:text-4xl text-tertiary md:leading-12 text-center"
                        initial="hidden"
                        whileInView="visible"
                        variants={slideInText(idx * 0.1)}
                        viewport={{ once: true }}
                    >
                        {line}
                    </motion.p>
                ))}
            </div>
        </section>
    );
};

export { HeaderSection };
