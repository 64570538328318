import { motion } from 'framer-motion';
import { YinYang } from '../shared/SVGs';
import { MarqueeLetter } from './MarqueeLetter';

type Props = {
    content: string[];
};

const marqueeVariants = {
    animate: {
        x: ['100%', '-100%'],
        transition: {
            x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: 40,
                ease: 'linear',
            },
        },
    },
};

const Marquee = ({ content }: Props) => {
    return (
        <div>
            <div className="relative w-full h-40 overflow-x-hidden">
                <motion.div
                    className="absolute whitespace-nowrap flex flex-row gap-8"
                    variants={marqueeVariants}
                    animate="animate"
                >
                    {content.map((item) => (
                        <div key={item} className="text-3xl md:text-6xl eagle flex flex-row items-center">
                            {item.split('').map((letter, idx) => (
                                <MarqueeLetter key={idx} letter={letter} />
                            ))}
                            <span className='ml-8'><YinYang /></span>
                        </div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};
export { Marquee };
