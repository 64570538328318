import { Direction } from "../types/types";

const slideInText = (delay: number) => {
    return {
        hidden: {
            y: '60%',
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 0.7,
                delay: delay
            }
        },
    }
}

const slideIn = (direction: Direction, duration: number, delay: number) => {
    return {
        hidden: {
            x: direction === Direction.LEFT ? '-100%' : '100%',
            opacity: 0,
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: 'tween',
                duration: duration,
                delay: delay,
            }
        }
    }
}

const slideUp = (duration: number) => {
    return {
        hidden: {
            y: '100%',
        },
        visible: {
            y: 0,
            transition: {
                type: 'tween',
                duration: duration,
            }
        }
    }
}

const fadeIn = (duration: number, delay?: number) => {
    return {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                duration: duration,
                delay: delay ? delay : 0,
            }
        },
    }
}

const fadeOut = (duration: number, delay?: number) => {
    return {
        opacity: 0,
        transition: {
            duration: duration,
            delay: delay
        }
    }
}

export { slideInText, slideIn, slideUp, fadeIn, fadeOut }