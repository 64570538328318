import TopImage from "../assets/AboutFYTSpiritImages/hero-image.jpg";
import Tongue from "../assets/AboutFYTSpiritImages/jazyk.jpg";
import { AboutFYTSpiritFAQType, Photo, Price } from "../types/types";

const FYTSpiritHeroImage: Photo = {
    src: TopImage,
    alt: "O FYT Spirit",
};

const aboutFYTSpiritIntro = {
    topText: [
        "Čínska medicína je moja vášeň. Venovala som jej roky štúdia a množstvo financií. Vo svojej praxi v Bratislave som počas 8 rokoch pomohla zlepšiť kvalitu zdravia mnohým ľuďom. V lete 2022 sme sa s rodinou rozhodli pre život v Nemecku. Nemecká legislatíva vyžaduje okrem vzdelania, ktoré som už nadobudla, aj zloženie skúšky Heilpraktikera (liečiteľa) na verejnom úrade zdravotníctva. Aby sa mi to podarilo, musím najskôr zlepšiť svoje jazykové schopnosti, a potom absolvovať kurz, ktorý mi pomôže pripraviť sa na tieto skúšky. Na tento vlak som už nasadla, ale dostať sa do cieľa mi ešte nejaký čas potrvá.",
        "Momentálne s viacerými klientami z Bratislavy naďalej konzultujeme ich stav a liečbu online. I keď sa naše osobné stretnutia zredukovali, darí sa nám ostať v kontakte a naďalej spolupracovať. Rozhodla som sa ponúknuť túto službu aj ostatným. Prečo by si ale niekto vybral konzultáciu online, keď môže ísť aj osobne za iným terapeutom TČM? Čo vlastne môžem ponúknuť?",
    ],
    bottomText: [
        "Pre mňa je najdôležitejšie, aby sa človek cítil príjemne a v bezpečí. Ponúkam priateľsky prístup a ochotu vysvetľovať a odpovedať na všetky otázky. Keď človek pochopí príčinu problému, už to je polovica úspechu. Pri online diagnostike sa môžete cítiť ako doma, lebo naozaj ste doma. :) Odpadá akýkoľvek stres z cestovania, zápchy alebo strach z neznámeho či medicínskeho prostredia. Možno vo svojom blízkom okolí terapeuta TČM ani nemáte.",
    ],
};

const therapyPrices: Price[] = [
    {
        item: "Vstupná diagnostika online (60-75 min)",
        price: 50,
    },
    {
        item: "Vstupná diagnostika osobne (90 min)",
        price: 60,
    },
    {
        item: "Vstupné diagnostika online – deti do 10 rokov (45-60 min)",
        price: 35,
    },
    {
        item: "Vstupné diagnostika osobne – deti do 10 rokov (60-75 min)",
        price: 45,
    },
    {
        item: "Kontrola (do 30 min)",
        price: 20,
    },
];

const productPrices = {
    heading: "Bylinné produkty",
    content:
        "Cena bylinných produktov je individuálna. Odráža sa od formy bylinného preparátu a od gramáže. Cena tabliet a granulátu na mesiac sa pohybuje od 35 do 50 eur a cena odvarov od 60 do 100 eur. Cena pre deti je 20-40 euro/mesiac.",
};

const aboutFYTSpiritFAQ: AboutFYTSpiritFAQType[] = [
    {
        question: "Pre koho je čínska medicína vhodná?",
        answer: [
            "1. Najväčšia skupina ľudí, ktorá vyhľadá takúto liečbu je tá, ktorej západná medicína nevie dlhodobo pomôcť. Je to v prípadoch, kedy nestačí liečiť symptóm, ale treba nájsť koreň ochorenia a riešiť ho od základov.",
            "2. Čínska medicína je vhodná pre ľudí, ktorí sa chcú vyhnúť farmakám, a chú sa liečiť prírodne.",
            "3. Je vhodná pre ľudí, ktorí nemajú žiadne veľké zdravotné problémy, ale chcú sa naďalej udržať fit. Správnou diagnostikou možno odhaliť aj jemné dysbalancie, ktoré by neskôr mohli vyústiť do väčších problémov. Tradiční čínski lekári boli platení , keď ich pacienti boli zdraví. Keď ochoreli, bolo to ich zlyhanie.",
            "Diagnostika je vhodná pre všetky vekové kategórie. Bylinný granulát sa dá podať už bábätkám rozpustený v mlieku.",
        ],
    },
    {
        question: "Ako sa treba na diagnostiku pripraviť?",
        answer: [
            "Súčasťou diagnostiky je aj diagnostika z jazyka. Preto budem potrebovať fotku vášho jazyka. Jazyk je najlepšie fotiť na dennom svetle. Tri hodiny predtým by sa nemala konzumovať káva alebo farebné cukríky, ktoré by jazyk zafarbili. Jazyk treba odfotiť celý, od jeho koreňa až po špičku.",
            "Najlepšie je, ak mi fotku pošlete ešte pred samotnou diagnostikou, aby som sa k nej už mohla vyjadriť.",
        ],
        image: {
            src: Tongue,
            alt: "Jazyk",
        },
    },
    {
        question: "Čo očakávať počas diagnostiky?",
        answer: [
            "Počas samotnej diagnostiky sa budeme hlavne rozprávať. Najskôr mi poviete, aký je váš hlavný zdravotný problém, ktorý chcete riešiť. Zaujíma ma, kedy sa objavili prvé príznaky, ich vývoj, intenzita, čo pomáha a zhoršuje problém.",
            "Potom sa porozprávame aj  o ostatných témach z oblasti vášho zdravia, aby som si vedela vytvoriť celkový obraz o vašej vrodenej konštitúcii, ale aj o aktuálnych výchylkách z vašej vnútornej rovnováhy.",
            "Na záver sa porozprávame o možnostiach bylinnej liečby a prípadných úpravách vášho životného štýlu a dietetiky. Diagnostika trvá približne hodinu.",
        ],
    },
    {
        question: "Čo sa bude diať po diagnostike?",
        answer: [
            "Moja práca po diagnostike nekončí. Pracujem ďalej na počítači, vypisujem si jednotlivé syndrómy, píšem si hlavný obraz, vedľajšie obrazy a princíp liečby. Potom nastáva najťažšia časť, a tou je vybrať z takmer 400 liečivých bylín 14-17 pre vás najvhodnejších v správnom dávkovaní. Zloženie receptu odosielam na bylinkáreň do Čiech, kde ho pre vás do 1-3 pracovných dní vyrobia a následne pošlú buď na vami zadanú adresu, alebo na pobočku v Bratislave na Stromovej ulici, kde si ho môžete vyzdihnúť.",
            "Po diagnostike vám takisto pošlem mail, v ktorom budú dietetické odporúčania podľa vašej konštitúcie a informácie k užívaniu bylín.",
        ],
    },
    {
        question: "Koľko trvá liečba a ako fungujú kontroly?",
        answer: [
            "Dĺžka liečby je individuálna. Záleží od intenzity, typu problému a od doby, kedy vznikol. Liečba môže trvať 2 mesiace, ale aj 2 roky. Najčastejšie sa pohybujeme medzi 3-6 mesiacmi.",
            "Keďže každý z nás je jedinečný, a reaguje na liečbu rôzne citlivo, je pre mňa ako terapeutku veľmi dôležité robiť pravidelnú kontrolu, a vedieť včas zareagovať úpravou receptu aj na malú odchýlku, ktorú si vy vôbec nemusíte všimnúť.",
            "Kontrolu stačí robiť raz za mesiac a trvá 15-30 minút. Termín si zarezervujete rovnakým spôsobom ako vstupnú diagnostiku.",
        ],
    },
];

export {
    FYTSpiritHeroImage,
    aboutFYTSpiritIntro,
    therapyPrices,
    productPrices,
    aboutFYTSpiritFAQ,
};
