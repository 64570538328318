import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Background, Color, SECTION_COLOR_VARIANTS } from '../../types/types';

const variants = (length: string) => {
    return {
        hidden: { width: 0, marginLeft: 0 },
        visible: { width: length, marginLeft: '12px' },
        transition: {
            type: 'tween',
            duration: 0.3,
            ease: 'easeOut',
        },
    };
};

const exitVariants = {
    width: 0,
    marginLeft: 0,
    transition: {
        type: 'tween',
        duration: 0.5,
        ease: 'easeOut',
    },
};

type Props = {
    color: Color;
    lines: Background;
    onClick: () => void;
};

const MenuButton = ({ onClick, color, lines }: Props) => {
    const [isHovered, setIshovered] = useState(false);

    return (
        <motion.button
            className={`absolute top-8 left-8 flex flex-row items-center pl-3 pr-6 py-3 ${SECTION_COLOR_VARIANTS[color]} md:text-xl font-bold z-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
            onMouseEnter={() => setIshovered(true)}
            onMouseLeave={() => setIshovered(false)}
            onClick={onClick}
        >
            <AnimatePresence>
                {isHovered && (
                    <div className="h-full flex flex-col justify-center gap-1">
                        <motion.span
                            className={`h-px ${lines}`}
                            variants={variants('24px')}
                            initial="hidden"
                            animate="visible"
                            exit={exitVariants}
                        />
                        <motion.span
                            className={`h-px ${lines}`}
                            variants={variants('16px')}
                            initial="hidden"
                            animate="visible"
                            exit={exitVariants}
                        />
                        <motion.span
                            className={`h-px ${lines}`}
                            variants={variants('8px')}
                            initial="hidden"
                            animate="visible"
                            exit={exitVariants}
                        />
                    </div>
                )}
            </AnimatePresence>
            <span className="ml-3">Menu</span>
        </motion.button>
    );
};
export { MenuButton };
