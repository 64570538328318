import { v4 as uuid } from 'uuid';
import { TextColor } from "../../types/types";
import { CalendlyButton } from "./Calendly";

type Props = {
    topText: string[];
    bottomText: string[];
    color: TextColor;
    addCalendly?: boolean;
}

const IntroCard = ({ topText, bottomText, color, addCalendly }: Props) => {
    return (
        <section className={`mx-auto w-full xl:w-2/3 bg-tertiary ${color} px-8 md:px-16 py-12 flex flex-col gap-12`}>
            <article className="flex flex-col gap-4 text-justify md:text-2xl border-b-2 border-b-primary pb-12">
                {topText.map((paragraph) => (
                    <p key={uuid()}>{paragraph}</p>
                ))}
            </article>
            <article className="flex flex-col gap-4 text-justify sm:text-2xl">
                {bottomText.map((paragraph) => (
                    <p key={uuid()}>{paragraph}</p>
                ))}
            </article>
            {addCalendly && <CalendlyButton />}
        </section>
    );
};
export { IntroCard };
