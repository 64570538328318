import { useState } from "react";
import { Helmet } from "react-helmet";
import { v4 as uuid } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { HeaderSection } from "../components/LandingPage/HeaderSection";
import { MenuButton } from "../components/Navigation/MenuButton";
import { Navigation } from "../components/Navigation/Navigation";
import { useFirstRender } from "../hooks/useFirstRender";
import { AboutMeSection } from "../components/LandingPage/AboutMeSection";
import { Background, Color } from "../types/types";
import { AboutTCMSection } from "../components/LandingPage/AboutTCMSection";
import { AboutFYTSpiritSection } from "../components/LandingPage/AboutFYTSpiritSection";
import { BlogSection } from "../components/LandingPage/BlogSection";
import { ScrollHandler } from "../components/LandingPage/ScrollHandler";
import { Logo } from "../components/shared/SVGs";
import { ContactSection } from "../components/shared/ContactSection";

const LandingPage = () => {
    const [showNavigation, setShowNavigation] = useState(false);

    const isFirstRender = useFirstRender();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <meta
                    name="theme-color"
                    content="#000000"
                />
                <meta
                    name="description"
                    content="FYT SPIRIT ponúka online diagnostiku tradičnej čínskej medicíny (TČM) z pohodlia vášho domova. Získajte individuálny bylinný recept a dietetické poradenstvo pre zlepšenie fyzického a duševného zdravia."
                />
                <meta
                    name="keywords"
                    content="FYT SPIRIT, tradičná čínska medicína, TČM, fytoterapia, diagnostika, online, zdravie, duševné zdravie, fyzické zdravie, bylinná liečba, dietetické poradenstvo, prevencia, choroby"
                />
                <meta
                    name="author"
                    content="Katarína Nagyová Šnircová"
                />
                <meta
                    property="og:title"
                    content="FYT SPIRIT | Domov"
                />
                <meta
                    property="og:description"
                    content="FYT SPIRIT ponúka online diagnostiku tradičnej čínskej medicíny (TČM) z pohodlia vášho domova. Získajte individuálny bylinný recept a dietetické poradenstvo pre zlepšenie fyzického a duševného zdravia."
                />
                <title>FYT SPIRIT | Domov</title>
            </Helmet>
            <div className="w-full overflow-x-hidden">
                <AnimatePresence>
                    {showNavigation && (
                        <Navigation
                            key={uuid()}
                            onExitClick={() => setShowNavigation(false)}
                        />
                    )}
                    {!showNavigation && (
                        <motion.main
                            key={uuid()}
                            exit={{
                                x: "100%",
                                transition: {
                                    type: "tween",
                                    delay: 0.9,
                                    duration: 0.1,
                                },
                            }}>
                            <MenuButton
                                color={Color.FUNKY_REVERSE}
                                lines={Background.FUNKY}
                                onClick={() => setShowNavigation(true)}
                            />
                            <HeaderSection isFirstRender={isFirstRender} />
                            <AboutFYTSpiritSection
                                color={Color.TERTIARY_DARK}
                            />
                            <AboutTCMSection color={Color.PRIMARY} />
                            <AboutMeSection color={Color.TERTIARY_LIGHT} />
                            <BlogSection color={Color.SECONDARY} />
                            <ContactSection
                                color={Color.DARK}
                                bigHeading
                            />
                        </motion.main>
                    )}
                </AnimatePresence>
                {window.innerWidth > 640 && <ScrollHandler />}
                <Logo />
            </div>
        </>
    );
};
export { LandingPage };
