import { useEffect } from "react";



const useScrollToTop = (deps?: any[]) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [deps])
}

export { useScrollToTop }