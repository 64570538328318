import { useState } from 'react';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { AboutFYTSpiritFAQType } from '../../types/types';
import { DownArrow, UpArrow } from '../shared/SVGs';

type Props = {
    faq: AboutFYTSpiritFAQType;
    variants: Variants;
};

const Accordion = ({ faq: { question, answer, image }, variants }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            variants={variants}
            viewport={{ once: true }}
            className="flex flex-col gap-4"
        >
            <div
                className="eagle bg-tertiary text-secondary md:text-3xl pl-8 md:pl-16 pr-16 py-4 md:py-8 cursor-pointer relative"
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <p>{question}</p>
                <span className="absolute top-1/2 -translate-y-1/2 right-4 md:right-6">
                    {isOpen ? <UpArrow /> : <DownArrow />}
                </span>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.article
                        className="flex flex-col items-center gap-4 bg-tertiary text-secondary md:text-2xl px-8 md:px-16 py-8 origin-top"
                        initial={{ transform: 'scale(1, 0)' }}
                        transition={{
                            type: 'tween',
                            duration: 0.3,
                        }}
                        animate={{ transform: 'scale(1)' }}
                        exit={{
                            height: 0,
                            paddingBlock: 0,
                            transition: { duration: 0.3, ease: 'linear' },
                        }}
                    >
                        {answer.map((paragraph, idx) => (
                            <p key={idx}>{paragraph}</p>
                        ))}
                        {image && <img className='sm:w-3/4 lg:w-1/2' src={image.src} alt={image.alt} />}
                    </motion.article>
                )}
            </AnimatePresence>
        </motion.div>
    );
};
export { Accordion };
