import { BlogPostType, BlogContentType, Category } from "../types/types";
import Migraine from "../assets/BlogImages/migrena.jpg";
import HurtBack from "../assets/BlogImages/krize.jpg";
import Stomach from "../assets/BlogImages/nafukovanie.jpg";
import Emotions from "../assets/BlogImages/emocie.png";
import Ear from "../assets/BlogImages/ucho.jpg";
import Infant from "../assets/BlogImages/infant.jpg";
import Belly from "../assets/BlogImages/belly.jpg";
import Toilet from "../assets/BlogImages/toilet.jpg";
import Bird from "../assets/BlogImages/bird.jpg";
import Chestnuts from "../assets/BlogImages/gastany.jpg";
import Island from "../assets/BlogImages/more.jpg";
import Diploma from "../assets/BlogImages/diplom.jpg";
import Hemoroid from "../assets/BlogImages/hemoroid.png";
import Ginger from "../assets/BlogImages/zazvor.png";
import EczemaAfter from "../assets/BlogImages/ekzem-po.png";
import EczemaBefore from "../assets/BlogImages/ekzem-pred.png";
import BeachLady from "../assets/BlogImages/na-plazi.png";
import Radish from "../assets/BlogImages/redkovky.png";
import Bicycle from "../assets/BlogImages/bicykel.png";
import Covid from "../assets/BlogImages/covid.png";
import Caj from "../assets/BlogImages/caj.png";
import ZimnaZelenina from "../assets/BlogImages/zimna-zelenina.png";
import ZimneBobule from "../assets/BlogImages/zimne-bobule.png";
import DraciSirup from "../assets/BlogImages/draci-sirup.png";
import Children from "../assets/BlogImages/children.png";

const blogPosts: BlogPostType[] = [
    {
        slug: "migreny",
        title: "Migrény (Gan Yang Shang Kang) a malé okienko do diagnostiky",
        topImage: Migraine,
        lede: "Gan Yang Shang Kang znamená „stúpajúci Yang pečene“. Je to obraz, ktorý postretol snáď každého dospelého človeka.",
        excerpt:
            "Gan Yang Shang Kang znamená „stúpajúci Yang pečene“. Je to obraz, ktorý postretol snáď každého dospelého človeka. Krásne na ňom vidno dôležitosť harmónie medzi Yinom a Yangom.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Gan Yang Shang Kang znamená „stúpajúci Yang pečene“. Je to obraz, ktorý postretol snáď každého dospelého človeka. Krásne na ňom vidno dôležitosť harmónie medzi Yinom a Yangom. Je to Yangový obraz, ktorý však vzniká na podklade nedostatku Yinu. Yin predstavuje hmotu, výživu, nehybnosť, chlad. Yang je, naopak, dynamický, teplý, rýchly. Krásne tento rozdiel vidieť na pohlavných bunkách. Predstavme si Yinové vajíčko, ktoré je pomalé, čakajúce a príjmajúce, zatiaľ čo spermia je yangová, rýchla, malá, svižná dobyvateľka. Ich splynutím vznikne nový organizmus, v ktorom sú zastúpené obe polarity.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Oni už totiž nejaký čas vysielali signály. Pobolievali a boli stuhnuté, však?  Ale dalo sa s tým fungovať. V pohode, dá sa ísť cez bolesť. Až kým toho nemajú fakt plné zuby. Nikto sa im nevenuje, nikto nerieši, prečo bolia a ako im pomôcť. Čo iné im ostáva než poriadne zakričať? Tak a je to.. Teraz sa ich nedá už ignorovať. Dostali nás, vyhrali. Ako malé dieťa, ktoré, kým iba potichu mrnčí, dá sa ignorovať. Ale potom spustí urputný rev z celého hrdla, tak ho musíme utíšiť, ak nechceme ohluchnúť (a nie iba preto;)). Viac sa však oplatí riešiť už mrnčanie, lebo to ide ľahšie než už vystupňovaný záchvat plaču.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pri diagnostike TČM terapeut posudzuje rovnováhu Yinu a Yangu v jednotlivých orgánoch. Pýta sa napr. na sucho v ústach, stav pokožky, nechtov a vlasov, pociťovanie chladu alebo horúčavy, kvalitu spánku, únavu a mnoho ďalšieho. Terapeut si na základe každého jedného symptómu urobí diagnostický záver a určí „hlavný obraz“, ktorý treba liečiť. Napr. bolesť hlavy, z hľadiska TČM, môže byť spôsobená 11 rozličnými obrazmi. Terapeut sa popýta na presnú lokalizáciu bolesti, charakter bolesti, čas, kedy sa bolesť objavuje a čo na ňu pomáha. To všetko terapeutovi napovie, ktorý z 11. možných obrazov je ten správny a podľa toho nastaví liečbu na mieru.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vráťme sa teda k nášmu obrazu Gan Yang Shang Kang. Je to najčastejší typ bolesti hlavy, s ktorým sa pri diagnostikách stretávam. Keď Yang nie je zakotvený v Yine ako by mal byť, stúpa nahor a spôsobí problémy v hlave. Patria sem migrény, bolesť na jednom alebo oboch spánkoch, bolesť a tlak za očami. Bolesti môžu byť zhoršené pred menštruáciou a môžu byť sprevádzané nevoľnosťou, zrakovými zmenami, stuhnutými trapézami, závratmi alebo pískaním v uchu.  Tieto symptómy sa môžu objaviť aj vtedy, keď hlava práve nebolí. Liečba migrény porieši aj tieto „vedľajšie symptómi“.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Možno sa pýtate, čo to znamená „keď Yang nie je zakotvený v Yine“. Aby Yang nezačal stúpať nahor, musí byť ponorený v „pečeňovom Yine“, ktorý ho drží na svojom mieste. To znamená, že ak máme Yinu nedostatok, nedokáže udržať pohyblivý Yang na svojom mieste, a ten začne robiť problémy v hornej časti tela.  Preto tento problém vzniká najčastejšie u prepracovaných a vyčerpaných ľudí alebo u ľudí s nedostatočnou výživou. K nedostatku Yinu patria aj tieto príznaky: suchosť slizníc, suchá pokožka, padanie vlasov, krehké nechty, poruchy spánku, slabá menštruácia, návaly, nočné potenie, búšenie srdca a iné.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Krásne na tom je, že do jednej bylinnej zmesy sa dávajú bylinky na „stlačenie Yangu“ a zároveň bylinky na výživu Yinu. V jednom recepte je okolo 15 byliniek, ktoré navzájom spolupracujú, a okrem hlavného problému poriešia aj tie menej obťažujúce, čím navrátia telo i ducha do celkovej harmónie.",
            },
        ],
        categories: ["diagnostika"],
        date: new Date(2022, 3, 30),
        isRecommended: true,
    },
    {
        slug: "au-moje-krize",
        title: "Au, moje kríže!",
        topImage: HurtBack,
        lede: "Poznáte to - niekam sa ponáhľate, máte na pláne tisíc vecí, idete vystúpiť z auta, vstať z postele alebo sa zdvihnúť zo stoličky a zrazu AU, PUK, RUPS, AU!",
        excerpt:
            "Poznáte to - niekam sa ponáhľate, máte na pláne tisíc vecí, idete vystúpiť z auta, vstať z postele alebo sa zdvihnúť zo stoličky a zrazu AU, PUK, RUPS, AU! Tak, a všetky plány sú v háji. Namiesto toho skončíte v posteli, ledva sa hýbete, pojedáte brufeny, ale akosi to neprechádza a bolesť vás paralyzuje.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Poznáte to - niekam sa ponáhľate, máte na pláne tisíc vecí, idete vystúpiť z auta, vstať z postele alebo sa zdvihnúť zo stoličky a zrazu AU, PUK, RUPS, AU! Tak, a všetky plány sú v háji. Namiesto toho skončíte v posteli, ledva sa hýbete, pojedáte brufeny, ale akosi to neprechádza a bolesť vás paralyzuje. Môžete zabudnúť na ďalšie lámanie rekordov na bicykli či umývanie okien. Musíte počkať, musíte sa zastaviť. Chcú nám tie kríže niečo povedať...?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Oni už totiž nejaký čas vysielali signály. Pobolievali a boli stuhnuté, však?  Ale dalo sa s tým fungovať. V pohode, dá sa ísť cez bolesť. Až kým toho nemajú fakt plné zuby. Nikto sa im nevenuje, nikto nerieši, prečo bolia a ako im pomôcť. Čo iné im ostáva než poriadne zakričať? Tak a je to.. Teraz sa ich nedá už ignorovať. Dostali nás, vyhrali. Ako malé dieťa, ktoré, kým iba potichu mrnčí, dá sa ignorovať. Ale potom spustí urputný rev z celého hrdla, tak ho musíme utíšiť, ak nechceme ohluchnúť (a nie iba preto;)). Viac sa však oplatí riešiť už mrnčanie, lebo to ide ľahšie než už vystupňovaný záchvat plaču.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Bolesť v krížoch je najčastejšia lokalizácia bolesti pohybového aparátu, s ktorou sa stretávam. Kríže  v čínskej medicíne spadajú pod oblasť obličiek. Na ich pohodu vplýva stav esencie Jing, ktorá sa nachádza v obličkách, a stav krvi Xue. Tupá bolesť vždy poukazuje na nejaký nedostatok. Keď nás kríže len pobolievajú, tak hovoríme o nedostatku obličiek Shen a ich esencie Jing. Pri diagnostike sa zisťuje, či je v prevahe nedostatok Yinovej alebo Yangovej zložky.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Kedy človeku hrozí nedostatok esencie? Zjednodušene povedané ide o vyčerpanie organizmu. Nedáme si stopku, lebo to sa nenosí alebo jednoducho nemôžeme. Do tohto stavu upadajú nadmerne pracujúci ľudia (mentálne či fyzicky), mamičky na materskej (esencia je vyčerpaná samotným tehotenstvom, pôrodom a následným nedostatkom spánku), starší ľudia (esencia sa vekom prirodzene spaľuje ako horiaca sviečka).",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "K správnej regenerácii telo potrebuje oddych, dostatok spánku a kvalitnú stravu. Únavu zaháňate kávou? Pozrime sa na to, čo sa stane. Namiesto oddychu a regenerácii dochádza k neprirodzenej stimulácii organizmu. Hovorí sa, že káva spaľuje zajtrajšiu energiu. Niet divu, že keď prvotný povzbudzujúci účinok vyprchá, tak sa cítite ešte unavenejšie. Siaha do rezerv. A kde sú tieto rezervy podľa čínskej medicíny? Presne tak, v obličkách. Je to naša esencia, ktorá, keď vyhasne, tak vyhasne náš život. Ja viem, že pre mnohých je káva aj skvelý chuťový zážitok, ale piť 3, 4 či 5 káv za deň si vyžiada svoju daň. Aj jedna úplne stačí.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "K seknutiu chýba už málo. Prichádza nečakane. Hovorí sa, že ide o blokádu na teréne nedostatku. Kde a prečo vzniká nedostatok, sme si už vysvetlili. Čo je to teda blokáda? Je to stagnácia Qi a krvi. Kde nie je koryto rieky plné vody, tam nemôže voda (Qi a krv) voľne prúdiť. Pri pohybe, pri ktorom nás sekne, neprejde Qi a krv voľne ako by mala, ale niekde sa zrazu zasekne, uviazne. A to je tá krutá paralyzujúca bolesť, ktorá nás už naozaj zastaví.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Našťastie, v čínskej medicíne poznáme spôsob, ako túto stagnáciu rozhýbať a ako znovu posilniť naše obličky. Viac o liečbe nabudúce.",
            },
        ],
        categories: ["motorika"],
        date: new Date(2021, 7, 15),
        isRecommended: true,
    },
    {
        slug: "vlna-neprijemneho-nafukovania",
        title: "Vlna nepríjemného nafukovania",
        topImage: Stomach,
        lede: "Svoju „čínsku“ prax mám už 7 rokov. Je zaujímavé sledovať ako ľudia prichádzajú vo vlnách s tými istými problémami.",
        excerpt:
            "Svoju „čínsku“ prax mám už 7 rokov. Je zaujímavé sledovať ako ľudia prichádzajú vo vlnách s tými istými problémami. Napr. príde vlna boľavých kolien či krížov. Potom vlna ekzémov či alergií. September sa niesol vo vlne nafukovania a nespavosti.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Svoju „čínsku“ prax mám už 7 rokov. Je zaujímavé sledovať ako ľudia prichádzajú vo vlnách s tými istými problémami. Napr. príde vlna boľavých kolien či krížov. Potom vlna ekzémov či alergií. September sa niesol vo vlne nafukovania a nespavosti. ",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Rovnaké problémy môžu mať rôzne príčiny. Tak to vidím aj v praxi, keď počas celkovej diagnostiky hľadám príčinu potiaží. Nafukovanie alebo plynatosť je nepríjemný stav spôsobený nahromedením plynov v črevách. Človek má pocit nafúknutého balóna v bruchu. Tento stav môže byť aj bolestivý. Dokonca sa môže prejaviť ostrou fixnou bolesťou v určitej oblasti brucha.  Pri nafukovaní treba zistiť, či je hlavný problém v slezinovej Qi, ktorá je zastagnovaná kvôli nedostatku pohybu, nadmerného premýšľania alebo nevhodnej strave.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Druhá príčina nafukovania môže ísť primárne od „pečene“. Vtedy hovoríme o stiesnenej pečeni Gan Yu, ktorá narúša Qi sleziny a jej hladké prúdenie. Pečeni prislúcha element drevo a slezine element zem. Môžeme to prirovnať koreňom stromu zasahujúcim do zeme. Ide o emočne podmienené nafukovanie a nafukovanie vrámci predmenštruačného syndrómu.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Najčastejšie ide o kombináciu obidvoch príčin, pri ktorej treba zostaviť bylinný recept na mieru a stimulovať vhodné akupunktúrne body.  K trvalému odstráneniu príčiny nafukovania je nevyhnutná spolupráca klienta. Pomôže úprava stravovacích návykov. Okrem zloženia jedálnička, treba dbať na pravidelnosť a sústredenie sa na jedlo (žiaden mobil, TV, noviny,...). Ak je nedostatok fyzickej aktivity, odporúčam zavedenie pravidelného pohybu a  keď treba, tak aj zvládanie stresu – prostredníctvom tvorivých činností, relaxácie, dychových cvičení či spolupráce so psychoterapeutom.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Aby to nebolo také jednoduché, treba spomenúť aj tretí typ nafukovania. Ide o premnoženie kvasiniek alebo iných patogénov v čreve. Či ide o tzv. Gu Zheng (Gu - červ, Zheng – obraz) opäť napovedia sprievodné príznaky a diagnostika z jazyka. Ten zvykne byť potiahnutý silným bielym povlakom alebo mapovitý – s výpadkami povlaku. Občas sa prejaví aj glositída – zápal jazyka. Vtedy jazyk bolí, páli alebo svrbí. Ľudia s Gu Zheng bývajú unavení, môžu trpieť bolesťami hlavy a často aj úzkosťami a depresiami. Majú riedku zapáchajúcu stolicu alebo naopak, zápchu. Symptómy sa u žien zhoršujú vrámci PMS.  Patria sem aj ľudia s rôznymi potravinovými intoleranciami. Častá je napr. histamínová intolerancia. Tento typ je najobtiažnejší na liečbu.  Vyžaduje si pevnú vôľu pacienta. Počas liečby je potreba dodržiavať prísnu diétu. Cieľom liečby je prestaviť črevnú flóru, kde dobré baktérie budú v prevahe nad kvasinkami a ostatnými patogénmi. Je to dlhodobý proces trvajúci najmenej 3 mesiace. Väčšinou však liečba trvá dlhšie, a to 6-12 mesiacov. Ku koncu liečby sa skúša tolerancia jednotlivých zakázaných potravín, ktoré by po liečbe už nemali spôsobovať problémy.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Nafukovanie je naozaj častý problém dnešnej doby. Vie spôsobiť veľa nepríjemností. Je to však vyriešiteľný problém.  Ak ním trpíte, neváhajte vyhľadať odbornú pomoc a zlepšiť kvalitu svojho života.",
            },
        ],
        categories: ["trávenie"],
        date: new Date(2021, 10, 1),
        isRecommended: true,
    },
    {
        slug: "5-organov-5-emocii",
        title: "5 orgánov = 5 emócií",
        topImage: Emotions,
        lede: "Dnes naša 6-ročná dcéra prvýkrát plakala pri filme.",
        excerpt:
            "Dnes naša 6-ročná dcéra prvýkrát plakala pri filme. Pozerala rozprávku Inside out (V hlave). Je to príbeh 11-ročnej školáčky Riley a jej emócii. Emócie v jej tele sú znázornené ako postavičky, ktoré striedavo preberajú kontrolu. Najväčším problémom pre Riley bol smútok.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Dnes naša 6-ročná dcéra prvýkrát plakala pri filme. Pozerala rozprávku Inside out (V hlave). Je to príbeh 11-ročnej školáčky Riley a jej emócii. Emócie v jej tele sú znázornené ako postavičky, ktoré striedavo preberajú kontrolu. Najväčším problémom pre Riley bol smútok. A to najmä pre to, že mu nedala povolenie prevziať kontrolu tak, ako by chcel. Zostal nepriznaný, nevyplakaný a potlačený. Naša dcérka plakala dlho a silno. Nechali sme to tak. Sedeli sme pri nej až kým to celé nevyplakala. Keď jej ten smútok prešiel, opäť cítila radosť a veselo skákala na gauči. Keby sme si aj my, dospelí, dovolili všetko vyplakať...",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Zadržané emócie dokážu v tele narobiť riadnu šarapatu. Zauzlia našu Qi v jednotlivých prislúchajúcich orgánoch. Čínska medicína sa opiera o teóriu 5 elementov: voda, drevo, oheň, zem a kov. Ku každému elementu prislúcha hlavný orgán, jeho párový orgán a jedna hlavná emócia.",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "VODA - obličky + močový mechúr - strach",
                    "DREVO - pečeň + žlčník - hnev",
                    "OHEŇ - srdce + tenké črevo - radosť",
                    "ZEM - slezina + žalúdok - neustále premietanie",
                    "KOV - pľúca + hrubé črevo - smútok",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Emócie sú normálnou súčasťou nášho každodenného života. Je úplne normálne, keď v určitých situáciach cítime hnev, smútok, radosť či strach. Pre naše psychické a fyzické zdravie je nesmierne dôležité vedieť s týmito emóciami pracovať. Žiaľ, veľa z nás bolo vychovaných spôsobom, ktorý nás to nenaučil. Mnohí sme vyrastali s vetami typu: „Chlapci predsa neplačú! Nebuď baba, pochlap sa!“ Alebo jednoducho naše skutočné potreby neboli vypočuté a pochopené. Rýchlo zahnať emócie a ísť ďalej je jednoduchšie, ako sedieť s plačúcim, nahnevaným či ustráchaným dieťaťom a snažiť sa ho pochopiť.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Aké škody to napácha?",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "už v detstve máme kopu nespracovaných emócií",
                    "prenášame si tieto vzorce správania do dospelosti",
                    "prenášame tieto vzorce správania na naše deti",
                ],
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Nahromadené emócie:",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "nahromadený smútok môže spôsobiť depresie",
                    "nahromadený strach úzkosti",
                    "nahromadený hnev z nás spraví nepríjemných a nervóznych ľudí a môže viesť k depresii",
                    "pri neustálom prehrávaní si udalostí, zabudneme žiť v prítomnosti",
                    "okrem toho, vzniká rad psychosomatických problémov, o ktorých si povieme nabudúce",
                ],
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Útek pred emóciami = závislosti",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "keď nevieme niečo „predýchať“, tak radšej siahneme po cigarete, ktorá nám s tým pomôže",
                    "keď potrebujeme uvoľniť potlačený hnev alebo frustráciu, tak sa nám žiada vínko, alebo aj silnejší alkohol",
                    "strachy a obavy nám nedovolia spávať, a tak sa rozptýlime stále zapnutou televíziou",
                    "pred emóciami utekáme aj prostredníctvom chorobného nakupovania, zajedania, gamblingu a drog",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ako si viete s emóciami poradiť vy? Nie vždy je spoločensky prijateľné vyjadriť ich tu a teraz. Čo s tým? Viete sa neskôr k emócií vrátiť a vyhnať ju z tela?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Napríklad, mne pri nezastaviteľnom prúdení myšlienok pomáha prechádzka v prírode, vedená meditácia, horúci kúpeľ, bachové kvapky White Chestnut alebo masáž nôh od môjho skvelého manžela.",
            },
        ],
        categories: ["psychológia"],
        date: new Date(2022, 1, 5),
        isRecommended: false,
    },
    {
        slug: "chronicky-upchaty-nos",
        title: "Chronicky upchtatý nos, problémy s dutinami, bolesti ušiek",
        topImage: Ear,
        lede: "Niekedy sa stane, že sa po akútnom ochorení cítime už v celku fajn, ale kdesi niečo nie je doliečené.",
        excerpt:
            "Niekedy sa stane, že sa po akútnom ochorení cítime už v celku fajn, ale kdesi niečo nie je doliečené. Kameňom úrazu často býva nos a dutiny. A tak si kvapkáme z reklám známe kvapky do nosa aj dlhšie ako je odporúčané na príbalovom letáku. Keď sa nám v nose a v dutinách usadí škodlivina, ktorá sa poriadne nedolieči, nazývame to Fu Xie (skrytý patogén).",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Niekedy sa stane, že sa po akútnom ochorení cítime už v celku fajn, ale kdesi niečo nie je doliečené. Kameňom úrazu často býva nos a dutiny. A tak si kvapkáme z reklám známe kvapky do nosa aj dlhšie ako je odporúčané na príbalovom letáku. Keď sa nám v nose a v dutinách usadí škodlivina, ktorá sa poriadne nedolieči, nazývame to Fu Xie (skrytý patogén). Tento patogén si u nás môže hovieť mesiace, ba dokonca až roky. Najväčšie problémy spôsobuje v noci, keď sa nehýbeme a opuchnutá sliznica (stagnácia krvi) sa ešte zväčšuje. Nos môže byť opuchnutý „na sucho“ alebo sa opuch môže kombinovať s hlienmi, ktoré väčšinou nejde dobre vysmrkať.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Liečila som pána, ktorý bol asi rok závislý na kvapkách do nosa. Liečila som pár žien, ktoré si síce kvapky do nosa nedávali, ale mali chronicky upchatý nos a bolesti dutín. Musím povedať, že kombinácia fytoterapie, akupresúry a nahrievania je priam zázračná. Treba prečistiť skrytú škodlivinu a odstrániť blokádu krvi Xue Yu – tá spôsobuje opuch. Liečba môže pomôcť aj u detičiek, ktorým hrozí vyberanie nosných mandlí.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Fu Xie sa môže usadiť aj v ušiach, a to najmä u detí. Jeden môj detský klient chodieval pravidelne na výplach uška, ktoré ho zvyklo bolievať. Takisto mal problémy so zastretými hlasivkami, zahlienením horných dýchacích ciest a upchatým nosom. Na liečbu som použila fytoterapiu – modifikovanú zmes Zhu Ru Wen Dan Tang. Na výplachu uška bol odvtedy už len raz (asi 2 týždne po začatí liečby). Túto zmes bral pol roka a pomohla mu na celkové odhlienenie, odstránenie problémov s uchom a vyčistením hlasiviek. Liečbu sme ukončili ešte druhým receptom na spriechodnenie nosa.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak máte problémy so skrytou škodlivinou, nečakajte zbytočne. Čínska medicína si s ňou hravo poradí.",
            },
        ],
        categories: ["orl", "dutiny"],
        date: new Date(2022, 1, 21),
        isRecommended: false,
    },
    {
        slug: "nastartovanie-porodu-prirodzenou-cestou",
        title: "Naštartovanie pôrodu prirodzenou cestou",
        topImage: Infant,
        lede: "Prenášanie v tehotenstve a prirodzené vyvolávanie pôrodu",
        excerpt:
            "Veľmi málo žien vie o možnosti vyvolania pôrodu stimuláciou akupunktúrnych bodov. Je to škoda, pretože funguje veľmi dobre a ušetrí ženu a bábätko od násilnejších intervencií v nemocnici.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Veľmi málo žien vie o možnosti vyvolania pôrodu stimuláciou akupunktúrnych bodov. Je to škoda, pretože funguje veľmi dobre a ušetrí ženu a bábätko od násilnejších intervencií v nemocnici.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ideálne je, aby tehuľka prišla na terapiu v deň vypočítaného termínu. Hovorí sa, že bábätko si vyberie deň svojho narodenia do piatich dní po stimulácii bodov.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Podľa mojich skúseností je to zvyčajne 2 dni po terapii a pôrody prebiehajú bez vážnejších komplikácií. V mojej praxi som mala aj bábätko, ktoré bolo otočené koncom panvovým. Mamička si priala rodiť vaginálne a lekári nechceli kvôli narastajúcej hmotnosti bábätka čakať dlhšie ako do vypočítaného termínu. Aj toto vyvolávanie bolo úspešné a mamička bezproblémovo porodila.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Takisto mám pozitívne skúsenosti s mamičkami, ktoré v minulosti porodili cisárskym rezom a druhé bábätko chceli porodiť vaginálne. Vtedy je dobré prísť na terapiu už v 39. týždni, a podľa potreby ju ešte 1-2 krát zopakovať.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Na terapii sa používajú akupunktúrne body, ktoré „sťahujú dole“, čiže podporujú zostup bábätka do pôrodných ciest a body, ktoré stimulujú funkciu maternice a dopĺňajú Qi, aby bola mamička pripravená na veľký energetický výdaj pri pôrode.",
            },
        ],
        categories: ["tehotenstvo", "pôrod"],
        date: new Date(2022, 7, 15),
        isRecommended: true,
    },
    {
        slug: "cesta-uspesnemu-umelemu-oplodneniu",
        title: "Cesta k úspešnému umelému oplodneniu",
        topImage: Belly,
        lede: "“Maličká je na svete. Katka, ďakujem za všetko. Mám pocit, že aj vám za ňu vďačíme.” – napísala mamička, ktorá sa rozhodla liečbu vyhľadať po niekoľkých neúspešných pokusoch IVF.",
        excerpt:
            "Príčiny ženskej neplodnosti sú rôzne a je ich celkom veľa. Vo všeobecnosti platí, že po 30tke schopnosť ženy počať klesá, a potom je to už každým rokom kúsok ťažšie. Poďme si vysvetliť, prečo to tak je a ako sa na tehotenstvo čo najlepšie pripraviť.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Príčiny ženskej neplodnosti sú rôzne a je ich celkom veľa. Vo všeobecnosti platí, že po 30tke schopnosť ženy počať klesá, a potom je to už každým rokom kúsok ťažšie. Poďme si vysvetliť, prečo to tak je a ako sa na tehotenstvo čo najlepšie pripraviť.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Číňania veria, že svoju životnú energiu máme ukrytú v obličkách v podobe esencie Jing, ktorá okrem iného, riadi rozmnožovanie a v tehotenstve sa významne podieľa na vývoji plodu. . Do určitého veku sa táto energia rozhojňuje. U žien je na svojom maxime vo veku 28 rokov, u mužov vo veku 32 rokov. S narastajúcim vekom množstvo obličkovej esencie Jing postupne klesá. Z hľadiska západnej medicíny klesá ovariálna zásoba a AMH (Anti-Müllerov hormón), ktorý reguluje dozrievanie folikulov. Dievčatá sa narodia približne s 1 miliónom vajíčok a tie postupne zanikajú. V puberte je ich cca 300 000 a po 30tke ich počet už rapídne klesá. Ovariálna zásoba sa už nedá nijak dorobiť. Ak sú vo vaječníku nedozreté vajíčka, dajú sa hormonálne vystimulovať, aby dozreli. Ak tam nie sú, tak už sa nedá robiť nič „západne ani čínsky“. Niektoré ženy síce menštruáciu majú, ale cykly môžu byť anovulačné. Ďalšie menštruáciu nemajú a hovoríme, že sú predčasne v klimaktériu. Ženy sa môžu rozhodnúť pre umelé oplodnenie, na ktoré sa môže použiť ich vlastné alebo darcovské vajíčko.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Po vklade embrya záleží na viacerých faktoroch, či je umelé oplodnenie úspešné. Z hľadiska TČM hovoríme o plynulom toku Qi a krvi, ktoré zabezpečia uchytenie embrya do výstelky maternice. Ak sú v podbrušku stagnácie Qi a krvi, môže to uchyteniu brániť. Ďalším problémom môže byť tzv. „chlad v maternici“, ktorý tiež narúša hladký tok Qi a krvi. Ak žena máva pri menštruácii silné bolesti, znamená to, že tok Qi a krvi je narušený stagnáciou alebo chladom.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Keď sa embryo úspešne v maternici uchytí, je jeho vývoj prvý trimester závislý najmä od esencie matky. Pokiaľ je matka v nedostatku esencie Jing, vývoj plodu sa spomalí alebo zastaví a dochádza k potratu. Preto je veľmi dôležité, aby žena bola dopredu pripravená na umelé oplodnenie. Doplnením esencie síce už vajíčka nevyrobíme, ale výrazne zvyšujeme šance na úspešné tehotenstvo po umelom oplodnení.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V čínskej medicíne sa používajú bylinky, ktoré rozbíjajú stagnácie v podbrušku, rozptyľujú chlad v maternici a dopĺňajú esenciu. Jednotlivé obrazy sa môžu medzi sebou kombinovať a podľa toho sa kombinujú aj bylinky.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Dôležitou súčasťou receptov na doplnenie esencie býva napr. Shu Di Huang (koreň rehmánie lepkavej). Koreň je čiernej farby, ktorá patrí k obličkám. Tropizmus má aj k pečeni a srdcu. Táto liečivka vyživuje esenciu Jing, ale aj krv Xue. Dopĺňa naše vlastné rezervy, z ktorých v tehotenstve čerpá plod. Svoje uplatnenie nájde aj pri anémii, pri problémoch s kolenami, bedrami, krížami, pri tinnite, predčasnom šedivení, celkovej vyčerpanosti, nespavosti a nočnom potení.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak máte problém s počatím alebo s udržaním tehotenstva, čínska medicína vám môže pomôcť na ceste za vytúženým bábätkom.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "“Maličká je na svete. Katka, ďakujem za všetko. Mám pocit, že aj vám za ňu vďačíme.” – napísala mamička, ktorá sa rozhodla liečbu vyhľadať po niekoľkých neúspešných pokusoch IVF. Väčšinou sa vajíčko neuchytilo vôbec alebo potratila čoskoro po pozitívnom tehotenskom teste. Jej telo si tvorilo protilátky proti spermiám a embryám. Nakoniec otehotnela počas liečby prirodzene a narodila sa jej krásna zdravá dcérka.",
            },
        ],
        categories: ["tehotenstvo", "fytoterapia"],
        date: new Date(2022, 6, 12),
        isRecommended: true,
    },
    {
        slug: "o-zapaloch-mocovych-ciest",
        title: "Príliš časté návštevy WC alebo O zápaloch močových ciest",
        topImage: Toilet,
        lede: "Liečba má 2 fázy – prečistenie akútnej škodliviny a podpora konštitúcie (v tomto prípade oslabených obličiek)",
        excerpt:
            "Naši lekári akútne zápaly močových ciest zachraňujú antibiotikami. Častokrát sa však stáva, že sa človek dostane s ich užívaním do jedného kolotoča. Z pohľadu TČM sú antibiotika silno ochladzujúce. To znamená, že s akútnym zápalom si poradia, ale nevyriešia príčinu.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pri problémoch s močovými cestami rozlišujeme 2 hlavné príčiny:",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "oslabený Yang obličiek",
                    "oheň srdca sa prepúšťa do dráhy tenkého čreva a odtiaľ do dráhy močového mechúra",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V obidvoch prípadoch ide pri zápale močového mechúra o symptómy nadbytku: tmavý zapáchajúci moč, zakalený moč, štípanie pri močení, bolesti alebo pálivý pocit v podbrušku, časté nutkanie na močenie, krv v moči.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V prvom prípade je na podklade nedostatok. Konkrétne ide o nedostatok Yangu obličiek Shen Yang Xu. Ten sa prejavuje celkovou zimomravosťou, chladnými nohami, pocitom chladu v oblasti krížov a bedier. Na spodku chodidiel sa nachádza počiatočný bod dráhy obličiek KI 1. Dokážeme cez neho nasať chlad do celej dráhy. Chlad do obličiek a močového mechúra môžeme nasať aj cez kríže, bedrá a zadok. Prejaví sa častým hojným močením priesvitného moču. Lenže, naše telo môže začať s týmto chladom bojovať. A to je moment, keď sa Yin mení v Yang, a príznaky začnú byť dramatické. Močový mechúr sa prehreje, moč sa zahustí, stmavne a dostavia sa vyššie spomenuté príznaky akútneho zápalu močových ciest.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V druhom prípade ide o psychicky podmienenú príčinu. Všetky naše nespracované emócie sa ukladajú v srdiečku a tu sa „prehrievajú“. Dráha srdca, aby ochranila nášho cisára – ducha Shen, prepúšťa tento nadbytok do združenej dráhy, ktorou je tenké črevo. Z tej nadbytok putuje ďalej do močového mechúra, kde sa prejaví zápal, ktorého symptómy už poznáme.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Naši lekári akútne zápaly močových ciest zachraňujú antibiotikami. Častokrát sa však stáva, že sa človek dostane s ich užívaním do jedného kolotoča. Z pohľadu TČM sú antibiotika silno ochladzujúce. To znamená, že s akútnym zápalom si poradia, ale nevyriešia príčinu. Hlavne, pokiaľ ide o nedostatok Yangu obličiek. Naopak, v tomto prípade, antibiotická liečba obličky ešte viac zachladí a prehĺbi nedostatok ich Yangovej Qi.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Čínska medicína používa na odstránenie akútneho zápalu bylinky na prečistenie vlhkej horúčavy Shi Re. Celkovo má takýto recept tiež antibiotický účinok. Lenže, tu liečba nekončí. Po vymiznutí akútnych symptómov prichádza na rad liečba koreňa ochorenia. Terapeut podľa diagnostiky nasadí vhodné bylinky na doplnenie Yangu obličiek alebo bylinky na dočistenie srdiečka a odblokovania emócií.",
            },
        ],
        categories: ["imunita", "chronické ochorenia"],
        date: new Date(2022, 5, 2),
        isRecommended: false,
    },
    {
        slug: "alergia-sa-znova-ozyva",
        title: "Alergia sa znova ozýva",
        topImage: Bird,
        lede: "Prejavy a liečba alergie očami TČM",
        excerpt:
            "Jar nám klope na dvere. Príroda sa prebúdza. Dni sa pomaličky predlžujú, sú teplejšie a slnečnejšie. Vyrástli prvé snežienky a na stromoch sa začali objavovať púčiky.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Jar nám klope na dvere. Príroda sa prebúdza. Dni sa pomaličky predlžujú, sú teplejšie a slnečnejšie. Vyrástli prvé snežienky a na stromoch sa začali objavovať púčiky.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Avšak, toto krásne obdobie môže byť pre alergikov nočnou morou. Čínska medicína vníma alergiu ako preniknutie vetra Feng do tela cez našu ochrannú bariéru Wei Qi. Liečba alergie v TČM sa zameriava jednak na akútne symptómy, ale aj na posilnenie konštitúcie jedinca.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content: "Akútne symptómy:",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "nádcha",
                    "upchatý nos",
                    "svrbenie alebo štípanie očí",
                    "svrbenie podnebia a uší",
                    "pálenie hrdla",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Všetko, čo nás na tele svrbí je prejavom vetra Feng, čiže základom liečby je „vyhnať vietor“. Ďalej sa tento vietor môže skombinovať s našou vnútornou horúčavou Re – vtedy vznikne zápal hrdla alebo očí. Používajú sa bylinky prečisťujúce horúčavu, ktoré liečia zápal očí alebo hrdla.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Alergie vznikajú, keď je naša Wei Qi nedostatočná. Na tvorbe Wei Qi sa podieľajú tieto orgány:",
            },
            {
                type: BlogContentType.LIST,
                content: ["slezina", "obličky", "pľúca"],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Správna diagnostika odhalí, ktorý orgán je najviac oslabený, a podľa toho sa nastaví konštitučná liečba, prípadne upraví jedálniček.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Konštitučnú liečbu je najlepšie začať 3 mesiace pred alergickou sezónou. Počas sezóny sa už oba princípy liečby kombinujú. Dávajú sa bylinky na elimináciu nepríjemných alergických prejavov a v menšom množstve bylinky na posilnenie konštitúcie a imunity.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Na zmiernenie akútnych symptómov funguje výborne aj aurikuloterapia. Ide o stimuláciu jednotlivých častí ucha, ktoré prislúchajú k jednotlivým častiam tela, pomocou semienok vakárie.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content: "TČM to zvládne!",
            },
        ],
        categories: ["alergia", "autoimunita"],
        date: new Date(2022, 3, 12),
        isRecommended: true,
    },
    {
        slug: "jedly-gastan",
        title: "LI ZI – jedlý gaštan",
        topImage: Chestnuts,
        lede: "Gaštan ako liek",
        excerpt:
            "Jeseň je už v plnom prúde a pri prechádzke mestom rozvoniavajú pečené gaštany. V tomto období by sme si ich mali dopriať pravidelne. Pani príroda dobre vie, čo robí. Posiela nám ich na jeseň, aby podporila naše celkové zdravie a imunitu v tomto chladnom a sychravom období.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Jeseň je už v plnom prúde a pri prechádzke mestom rozvoniavajú pečené gaštany. V tomto období by sme si ich mali dopriať pravidelne. Pani príroda dobre vie, čo robí. Posiela nám ich na jeseň, aby podporila naše celkové zdravie a imunitu v tomto chladnom a sychravom období.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Gaštany by som kľudne nazvala pokladom jesene. Sú nabité vitamínmi, minerálmi a antioxidantmi. Obsahujú vitamíny skupiny B, E, K a dokonca aj veľké množstvo vitamínu C. Takisto v nich nájdeme železo, vápnik, draslík, zinok, fosfor a horčík.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Z hľadiska TČM sú gaštany povahy teplej, chuťovo sladké a tropizmus majú k obličkám, slezine a žalúdku:",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Výborne dopĺňajú našu vzácnu esenciu Jing, ktorá sa nachádza v obličkách – podporujú dlhovekosť, pomáhajú pri problémoch v krížovej oblasti, posilňujú kolená, bedrá a kosti. Množstvo našej esencie ovplyvňuje ženskú aj mužskú plodnosť a schopnosť donosiť dieťa. Esencia zodpovedá aj za farbu vlasov a tiež za našu pamäť.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vďaka vplyvu na slezinu a žalúdok harmonizujú trávenie – zmierňujú nevoľnosť, pomáhajú odvádzať prebytočné vlhko, a tým napomáhajú chudnutiu a zastaveniu hnačiek. Cez doplnenie slezinnej Qi posilňujú aj cievny systém. Pomáhajú pri problémoch s kŕčovými žilami a hemoroidmi.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Platí, že je lepšie zjesť menšie množstvo gaštanov častejšie než nárazovo veľké množstvo. Odporúčaná denná dávka je 6-8 kusov.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Príprava pečených gaštanov",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "Gaštany prepláchneme a namočíme do vody aspoň na hodinu. Môžeme ich nechať namočené aj dlhšie. Kľudne aj 8 hodín. Gaštany tak ostanú pri pečení vláčne a mäkké.",
                    "Gaštany narežeme do kríža.",
                    "Pečieme ich pri 200 stupňoch 20 minút.",
                    "Po pečení ich ešte horúce dáme do igelitového sáčku alebo ich pokropíme vodou a zabalíme do mokrej utierky a necháme ich 5 minút odležať. Takto sa nám gaštany budú lepšie šúpať.",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content: "Prajem vám dobrú chuť!",
            },
        ],
        categories: ["dietetika", "imunita"],
        date: new Date(2021, 9, 17),
        isRecommended: false,
    },
    {
        slug: "vitamin-d",
        title: "Slnečný vitamín – vitamín D",
        topImage: Island,
        lede: "Vitamín D a jeho prepojenie s čínskou medicínou",
        excerpt:
            "V tomto období s prevahou Yinu (tma, chlad, mesiac, noc) nesmieme zabúdať na substitúciu vitamínu D, ktorého tvorba v tele je spojená so slnečným žiarením. Existuje len málo potravín s obsahom Déčka. Nachádza sa v rybách (najviac v lososovi), pečeni, vaječných žĺtkoch, obohatených mliečnych výrobkoch a v avokáde.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V tomto období s prevahou Yinu (tma, chlad, mesiac, noc) nesmieme zabúdať na substitúciu vitamínu D, ktorého tvorba v tele je spojená so slnečným žiarením. Existuje len málo potravín s obsahom Déčka. Nachádza sa v rybách (najviac v lososovi), pečeni, vaječných žĺtkoch, obohatených mliečnych výrobkoch a v avokáde.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Jeho výborným zdrojom je slnečné žiarenie. Zo slnečných lúčov preniká vitamín D do tela cez pokožku, ktorá nesmie byť natretá opaľovacím krémom. Potom sa pečeň a obličky podieľajú na jeho premene do aktívnej formy D3. Okrem toho, ak je aj vonku dostatok slniečka, ale trávime dni zavretí v kanceláriách, tak môžeme nedostatkom trpieť celoročne. Takisto môžeme mať málo vitamínu D, ak by sme celé dni trávili opaľovaním, ale naša pečeň a obličky by nefungovali úplne dobre.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vitamín D v čínskej medicíne predstavuje Yangovú Qi. Yang v prírode predstavuje slnko, svetlo, deň, teplo. Yang v našich telách predstavuje takisto teplo, energiu, dynamiku, pohyb. Nemôžeme povedať, že Yang v našom tele sa rovná vitamín D, ale môžeme povedať, že vitamín D je dôležitou súčasťou nášho Yangu. ktorý zahrieva orgány a je ich palivom. Najčastejšie Yang postrádajú obličky a slezina, ktoré sú zodpovedné za množstvo procesov v našich telách.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content:
                    "Aké sú teda problémy súvisiace s nízkou hladinou vitamínu D?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vo svojej praxi sa najčastejšie stretávam so psychickými a gynekologickými problémami. Väčšina z nich sa deje práve na podklade nedostatku Yangu obličiek a esencie Jing, o ktorej som už písala vo viacerých článkoch.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Je známe, že depresie a úzkosti sa zhoršujú na jeseň a v zime, a aj to, že väčší počet samovrážd sa vyskytuje v severnejších štátoch, kde je celkovo menej teplých a slnečných dní. Vitamín D prispieva k tvorbe serotonínu, známeho ako hormón dobrej nálady.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ďalším rozšíreným problémom je neplodnosť spojená s predčasným ovariálnym zlyhávaním – zníženou funkciou vaječníkov pred 40. rokom života. Žena môže, ale nemusí mať menštruáciu. Ak menštruáciu má, cykly sú často anovulačné a nepravidelné. Tieto ženy majú hodnotu Anti-Müllerovho hormónu (AMH – hormón produkovaný vaječníkmi, ktorý ukazuje počet zvyšných vajíčok) nižšiu ako 1 ng/ml. Takisto je u týchto žien preukázaný veľký nedostatok vitamínu D. Keďže vitamín D je tiež nevyhnutný pre vstrebávanie vápnika, jeho nedostatok vedie aj k osteopénii (zníženie hustoty kostí, predstupeň osteoporózy) a neskôr k samotnej osteoporóze, ktorá je typická pre klimaktérium a predčasné ovariálne zlyhávanie.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Celková únava a oslabená imunita môže tiež súvisieť s nízkou hladinou vitamínu D. Keď nemáme dostatok Yangovej Qi, tak sme v chladných mesiacoch náchylnejší na preniknutie „chladnej škodliviny“ do tela. V čínskej medicíne je to chladný vietor Feng Han a vlhký chlad Shi Han. V podstate sa jedná o vírusové ochorenia, ktoré sú teraz tak rozšírené. Ak si kladiete otázku, či k nim patrí známy C vírus, tak áno, patrí. Predstavme si leto, slnko, Yang. Vtedy všeobecne menej trpíme na vírusové ochorenia alebo sú na scéne iné typy (napr. spôsobujúce gastrointestinálne problémy) . Akonáhle príde jeseň, tak je dôležité vybudovať si vo svojom organizme dostatočne Yangové prostredie, aby odolalo vonkajším chladným škodlivinám. Pomôcť nám k tomu môže dostatok varenej a termodynamicky teplej stravy a suplementácia vitamínu D. Ak sme vo veľkom nedostatku Yangovej Qi, môžeme si pomôcť čínskou fytoterapiou a moxovaním vybraných akupunktúrnych bodov.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Odporúčaná denná dávka vitamínu D je 1000-2000 IU. V extrémnych prípadoch sa odporúča brať až 10000 IU. Najlepšie je nechať si urobiť vyšetrenie z krvi a poradiť sa so svojím lekárom.",
            },
        ],
        categories: ["dietetika", "imunita"],
        date: new Date(2021, 2, 26),
        isRecommended: true,
    },
    {
        slug: "omladzovacie-techniky-tvare",
        title: "Omladzovacie techniky tváre",
        topImage: Diploma,
        lede: "Ako môže TČM pomôcť omladnúť",
        excerpt:
            "Nedávno som absolvovala seminár, na ktorý som mala prvýkrát príležitosť ísť už pred ôsmimi rokmi. Vtedy som mala 26 a hovorila som si, že to nie je nič pre mňa, že ja nechcem ľudí skrášľovať, ale liečiť.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Nedávno som absolvovala seminár, na ktorý som mala prvýkrát príležitosť ísť už pred ôsmimi rokmi. Vtedy som mala 26 a hovorila som si, že to nie je nič pre mňa, že ja nechcem ľudí skrášľovať, ale liečiť.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ako roky plynú a vrásky pribúdajú aj mne, tak som to začala prehodnocovať. Naďalej si myslím, že vrásky k veku patria a dôležitejšie je naše vyžarovanie. Lenže, vždy máme krajšie vyžarovanie, keď sa cítime spokojne. K spokojnosti nám môže pomôcť práve aj pocit byť krásnou. A byť krásna je tiež proces, ktorý ide ruka v ruke s našim vnútrom. Keď športujeme, kultivujeme naše telo aj ducha. Vyplavené hormóny počas športu a radosť z pohybu sú na nezaplatenie. Tak ako sa staráme o naše telo – športujeme, spravíme si kúpeľ, občas si doprajeme masáž, sa môžeme postarať aj o našu tvár. V súčasnosti je internet plný tvárovej gymnastiky a masáži. Takéto techniky nám prinášajú pocit celkového uvoľnenia. Predsa len, na tvári sa zhromažďuje napätie, a to sa dá masážou, pri ktorej stimulujeme aj akupresúrne body, pekne uvoľniť. Efekt čínskych omladzovacích techník tváre by som prirovnala práve tvárovej gymnastike a masáži. Rozdiel je však v tom, že efekt je rýchlejší a nemusíte na ňom pracovať samé, ale postará sa o vás niekto iný, kým vy relaxujete.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Čo možno od terapie očakávať?",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "výraznú redukciu vrások,",
                    "nadvihnutie ovisnutých častí tváre (obočie, horné viečka, líčka),",
                    "zosvetlenie až úplné vymiznutie pigmentových škvŕn,",
                    "zmiernenie kruhov a vačkov pod očami,",
                    "zmiernenie asymetrií na tvári,",
                    "príjemne strávený čas s pocitom relaxácie.",
                ],
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Ako to funguje?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V terapii sa využívajú metódy, ktoré zásobia tvár Qi a krvou – dovedú do tváre živiny, prekrvia ju a podporia tvorbu vlastného kolagénu. Všetky tieto aspekty pomáhajú k vyhladeniu vrások. Pri redukcii pigmentových škvŕn rozbíjame stagnácie krvi, ktoré vznikli následkom brania hormonálnej antikoncepcie, opaľovania alebo lokálneho tlaku, napr. od okuliarov.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pri väčšom deficite je výhodné terapiu obohatiť o bylinnú liečbu, ktorá doplní rezervy do celého tela a omladí aj naše orgány, čím predchádza aj samotnej tvorbe vrások a ďalších problémov na tvári – napr. vačkov pod očami, ktoré môžu vznikať ako dôsledok oslabenej funkcie pľúc, sleziny a obličiek.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pre viditeľné výsledky sa odporúča absolvovať 6-10 terapií v intervaloch 1-2 krát týždenne.",
            },
        ],
        categories: ["pokožka"],
        date: new Date(2021, 2, 22),
        isRecommended: true,
    },
    {
        slug: "trapenie-menom-hemoroidy",
        title: "Trápenie menom hemoroidy",
        topImage: Hemoroid,
        lede: "Prečo vlastne vznikajú, aké poznáme druhy hemoroidov a ako ich úspešne liečiť.",
        excerpt:
            "Hemoroidy sú cievy, ktoré sa nachádzajú v sliznici v oblasti konečníka. Tieto cievy sa môžu zapáliť, krvácať alebo v nich môže vzniknúť krvná zrazenina. V oblasti konečníka môžeme cítiť nepríjemnú bolesť, svrbenie či pálenie. Problémy s hemoroidmi alebo s tzv. „zlatou žilou“ môžu začať v tehotenstve kvôli zvýšenému tlaku na panvové dno a tým pádom aj na konečník alebo pri samotnom tlačení pri pôrode.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Hemoroidy sú cievy, ktoré sa nachádzajú v sliznici v oblasti konečníka. Tieto cievy sa môžu zapáliť, krvácať alebo v nich môže vzniknúť krvná zrazenina. V oblasti konečníka môžeme cítiť nepríjemnú bolesť, svrbenie či pálenie. Problémy s hemoroidmi alebo s tzv. „zlatou žilou“ môžu začať v tehotenstve kvôli zvýšenému tlaku na panvové dno a tým pádom aj na konečník alebo pri samotnom tlačení pri pôrode. To však nie je jediný prípad. Problémy s hemoroidmi postihujú aj iné ženy či mužov. K ich vzniku prispieva hlavne nadmerné sedenie a problémy so stolicou, či už zápcha alebo hnačka.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Hemoroidy nám môžu narobiť problémy vo vnútri konečníka alebo môžu z neho aj „vyliezť“, a vtedy hovoríme o vonkajších hemoroidoch. Niekedy hemoroidy vyliezajú von iba pri tlaku a pri vyprázdňovaní, a potom sa vrátia naspäť samé alebo ich je možné zatlačiť späť. Niektoré sú vonku stále a tie zvyknú byť veľmi bolestivé.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Z pohľadu čínskej medicíny pri chronických problémoch s hemoroidmi najčastejšie ide o oslabenie a prepad Qi sleziny, ktorej funkciou je, okrem iného, udržiavať pevné cievy a krv v cievach. Ďalšími sprievodnými príznakmi môže byť pocit ťažoby, kašovitá stolica, závrate po postavení, únava. Na liečbu sa používa zmes bylín s hlavnou liečivkou Huang Qi (Radix Astragali, kozinec blanitý), ktorý dopĺňa a dvíha slezinovú Qi.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak problémy s hemoroidmi nastanú v dôsledku tlaku na tieto cievy, či už z dôvodu zápchy, tehotenstva alebo pôrodu, hovoríme o blokáde krvi Xue Yu. Hemoroidy sú vtedy bolestivé a v dôsledku možnej trombotizácie môžu byť aj opuchnuté.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ďalšou príčinou nepríjemných pocitov v tejto oblasti býva prítomnosť vlhkej horúčavy Shi Re v hrubom čreve. Tá spôsobí krvácanie a pálenie hemoroidov a konečníka. Ďalším typickým príznakom je kašovitá zapáchajúca stolica sprevádzaná kŕčovitými bolesťami brucha.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V prípade, že si nájdeme na papieri krv, ale inak o hemoroidoch nevieme, krv z ciev vytlačil tzv. vietor Feng, ktorý môže byť v tele prítomný z vnútorných alebo vonkajších príčin. Vietor z vnútorných príčin vzniká z nedostatku pečeňovej krvi Gan Xue. Nedostatok krvi nechá v cievach priestor pre vietor a ten potom z oslabených ciev vyženie krv von.  Vonkajší vietor sa dostane do tela pri napadnutí vonkajšou škodlivinou a krvácanie z konečníka je sprievodným príznakom infekčnej hnačky.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Na všetky tieto stavy existuje v TČM účinná bylinná liečba, ktorú terapeut môže ďalej upravovať podľa konštitúcie alebo prípadného prelínania príznakov.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Samozrejme, pomôcť si môžeme aj lokálne. V prípade vnútorných hemoroidov využijeme hlavne čapíky do konečníka (napr. homeopatické čípky Avenoc). Keď hemoroidy trápia z vonka, využijeme hlavne maste (tiež existuje masť Avenoc), obklady (napr. Hemoral – zoženiete v predajniach DM)  či kúpele (napr. z dubovej kôry).",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pokiaľ sa chceme vyhnúť komplikáciam s hemoroidmi, ktoré môžu viesť až k chirurgickému zákroku, je dobré tento problém nepodceňovať a riešiť ho komplexne úpravou životosprávy, vnútornou i vonkajšou liečbou.",
            },
        ],
        categories: ["chronické ochorenia"],
        date: new Date(2021, 1, 16),
        isRecommended: true,
    },
    {
        slug: "zazvor",
        title: "Zázvor - surový, sušený či pražený",
        topImage: Ginger,
        lede: "Zázvor a jeho široké využitie doma i v čínskej medicíne.",
        excerpt:
            "Zázvor je jedna z liečiviek čínskej medicíny bežne dostupných a využívaných aj v našich končinách. Pripravujeme z neho čaj a používame ho aj pri varení či pečení. Aký má vlastne vplyv na organizmus? Viete, že čaj z čerstvého zázvoru pôsobí inak ako čaj zo sušeného zázvoru?",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Zázvor je jedna z liečiviek čínskej medicíny bežne dostupných a využívaných aj v našich končinách. Pripravujeme z neho čaj a používame ho aj pri varení či pečení. Aký má vlastne vplyv na organizmus? Viete, že čaj z čerstvého zázvoru pôsobí inak ako čaj zo sušeného zázvoru?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V gastronómii i liečebne využívame podzemok zázvoru (Zingiberis officinalis rhizoma). V TČM využívame jeho 3 úpravy:",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "Sheng Jiang – surový zázvor",
                    "Gan Jiang – sušený zázvor",
                    "Pao Jiang – pražený sušený zázvor",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vo všeobecnosti je zázvor termodynamicky veľmi teplý, má ostrú chuť a má tropizmus k pľúcam, slezine a k žalúdku. Poďme si povedať o rozdieloch v jednotlivých úpravách.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "SHENG JIANG",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "1. Surový zázvor sa najčastejšie využíva na prípravu horúceho čaju pri začínajúcej viróze. Keď do tela prenikne chladný vietor Feng Han a my cítime, že „na nás niečo lezie“, tak je správny čas siahnuť po zázvorovom čaji. V praxi ho použijeme vtedy, keď máme zimnicu, škrabanie v krku, nádchu, kýchame a môžeme mať mierne zvýšenú teplotu. Takýto čaj pôsobí v povrchových vrstvách organizmu – otvára póry, spúšťa potenie a tak vypudzuje škodlivinu Feng Han von z tela. Po vypití čaju treba zaliezť pod perinu a vypotiť sa. Ak by sme šli s „otvoreným povrchom“ do chladu, tak by sme mohli do oslabeného tela natiahnuť ešte viac chladnej škodliviny.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content: "Recept na prípravu čaju:",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Odrežeme si cca 2 cm dlhý kúsok zázvoru.. Ošúpeme a nakrájame ho na malé kocky. Tie vložíme do hrnca s 0,5 l vriacej vody a varíme 15 min. Čaj scedíme a pomaly pijeme. Z hľadiska TČM nie je vhodné do čaju pridávať citrón, pretože ten svojou chladnou termodynamikou a kyslou chuťou vťahuje škodlivinu dovnútra. Naopak, jeho účinok umocníme pridaním 1 kúska celej kôry škorice. Pre lepšiu chuť môžeme pridať trochu medu.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content: "Kedy radšej nie:",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak sú príznaky ochorenia dramatickejšie a máme vysokú horúčku, silné bolesti v hrdle alebo smrkáme hustý zafarbený hlien, tak do tela prenikol horúci vietor Feng Re alebo pôvodne chladná škodlivina sa už v tele prehriala a zázvorový čaj v tomto prípade nie je vhodný.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "2. Surový zázvor má tiež harmonizačný vplyv na žalúdok, preto sa využíva aj pri nevoľnostiach z rôznych príčin – môže ísť o nevoľnosti pri virózach, z prejedenia alebo z pokazeného jedla. Takisto sa využíva na zmiernenie nevoľností v tehotenstve, kedy tehotná žena môže v malých množstvách popíjať čaj alebo cmúľať zázvorové cukríky či lízatká, ktoré dostať kúpiť v lekárniach.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "GAN JIANG",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Sušený zázvor v našej kuchyni nájdeme ako čaj alebo ako korenie. Z hľadiska čínskej medicíny zohrieva organizmus viac v hĺbke v jednotlivých orgánoch. Vylučuje chlad zo stredného ohniska Zhong Jiao, a tým prispieva k obnoveniu Yangu sleziny. Lieči tak trávenie, ak trpíme hnačkami z chladu (po surovom ovocí a zelenine, po zmrzline či chladných nápojoch) a prispieva k celkovému zahriatiu organizmu a studených rúk.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Rozptyľuje aj akútny či chronický chlad v pľúcach FEI HAN – pri vykašliavaní riedkeho alebo bieleho hlienu. Chlad v pľúcach sa prejavuje aj vodnatým výtokom z nosa alebo kvapkou vody pri nose.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "PAO JIANG",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ide o praženú formu sušeného zázvora, ktorá sa používa na zastavenie nadmerného krvácania pri nedostatku Yangu (krv je riedka a svetlá). Ide o krvácanie z maternice alebo krvácanie z konečníka.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Treba upozorniť, že nie každé nežiadúce krvácanie je z nedostatku Yangu a v niektorých prípadoch by Pao Jiang mohol krvácanie ešte zhoršiť, preto si ho prosím nenasadzujte bez presnej diagnostiky.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ako vidíte, jedna liečivka môže mať viacero terapeutických využití. V bežnom živote najviac využijeme liečivú silu surového zázvoru na boj proti virózam a na harmonizáciu žalúdka pri nevoľnostiach. Sušený a pražený zázvor je v čínskej medicíne súčasťou komplexnejších receptov na problémy spojené s trávením, chladom alebo krvácaním.",
            },
        ],
        categories: ["dietetika"],
        date: new Date(2020, 11, 4),
        isRecommended: true,
    },
    {
        slug: "liecba-atopickeho-ekzemu",
        title: "Liečba atopického ekzému",
        topImage: BeachLady,
        lede: "Krásna zdravá pokožka nie je samozrejmoťou. Čoraz viac ľudí trpí atopickým ekzémom. Prečítajte si, prečo mastičky nefungujú a čo viac môžete spraviť pre jeho liečbu.",
        excerpt:
            "V dnešnej dobe čoraz viac ľudí trpí na atopické ochorenia. Jedným z nich je atopický ekzém. Kto sa s ním stretol na vlastnej koži, veľmi dobre vie ako môže aj malý fliačik spôsobiť nepríjemné pocity. Niekedy je však ekzémom postihnutá veľká časť kože a takýto rozsiahly ekzém dokáže pekne znepríjemniť život – svrbením, pocitmi pálenia, krvácaním, mokvaním a neraz aj svojim vzhľadom.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V dnešnej dobe čoraz viac ľudí trpí na atopické ochorenia. Jedným z nich je atopický ekzém. Kto sa s ním stretol na vlastnej koži, veľmi dobre vie ako môže aj malý fliačik spôsobiť nepríjemné pocity. Niekedy je však ekzémom postihnutá veľká časť kože a takýto rozsiahly ekzém dokáže pekne znepríjemniť život – svrbením, pocitmi pálenia, krvácaním, mokvaním a neraz aj svojim vzhľadom. Hlavne, ak sa nachádza na odkrytých častiach tela.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vyskúšali ste už desiatky rôznych mastí, či už predpísaných od kožného lekára alebo voľne dostupných v lekárňach, a nič dlhodobo nepomáha? Problém je v tom, že aj keď to tak pôsobí, kožné ochorenia nie sú povrchové. Ich príčina je skrytá vnútri nášho tela, preto väčšinou pre komplexnú liečbu nestačí potierať postihnutú kožu masťami. Ak ich natrieme aj tými najsilnejšími kortikoidmi a ekzém sa stratí, zvykne sa vrátiť v ešte väčšom rozsahu. Namiesto toho, aby sme zápal odstránili, kortikoidy ho zatlačia dovnútra organizmu a keď ich prestaneme používať, telo začne nazhromaždenú škodlivinu opäť vypudzovať cez kožu.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V čínskej medicíne hovoríme o atopickom ekzéme ako o vlhkej horúčave Shi Re, ktorá je v tele zastúpená v rôznom pomere. U niekoho prevláda viacej vlhkosti Shi, vtedy sa ekzém vyskytuje viacej v spodnej časti tela, je ohraničený a vystúpený nad kožu, pri rozškrabaní mokvá. Takýto človek môže mať viacej problémov spôsobených nadbytočnou vlhkosťou a to napr. problémy so stolicou, nevoľnosť, ženy môžu mať gynekologické výtoky a typický je mastný povlak na jazyku. Ekzém sa výrazne horší s nesprávnou dietetikou, a to najmä s príjmom sladkostí, mastných jedál, mliečnych produktov a pečiva.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak v tele prevláda horúčava Re, koža je suchá, červená, ložiská sa vyskytujú viacej v hornej časti tela, viacej svrbia a pri rozškrabaní krvácajú. Človek s týmto typom ekzému môže mať ďalšie príznaky horúčavy Re a zároveň nedostatku Yinu – celkovo suchú kožu či sliznice, červené oči, bolesti hlavy, jazyk býva červený. Tento typ ekzému sa zvykne zhoršovať v období stresu a tiež s nesprávnou dietetikou. Horúčavu do tela pridávajú pečené a vyprážané jedlá, pikantné koreniny, káva, alkohol a cigarety.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Trošku inou kapitolou sú ekzémy bábätiek. Väčšinou ide o tzv. horúci plod Tai Re, keď sa dieťatko čistí od škodlivín prenesených od svojich rodičov – geneticky alebo nesprávnou životosprávou či stresom matky v období tehotenstva a kojenia. Platí, že čím skôr sa začne ekzém liečiť, o to ľahšie to ide. Bábätkám sa podávajú bylinky vo forme granulátu. Môžu sa zmiešať s materským či umelým mliekom, neskôr sa podávajú zamiešané v príkrmoch.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Ako sa teda na trvalo zbaviť atopického ekzému?",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "správnou dietetikou,",
                    "zdravým životným štýlom – vyhýbať sa stresu pokiaľ sa dá a vedieť s ním pracovať, keď už príde,",
                    "vydržať a nenatierať sa kortikoidmi,",
                    "prečistením organizmu bylinami – skúsený terapeut správne odhadne pomer vlhka a horúčavy v organizme a podľa toho upraví bylinnú receptúru na mieru,",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak sa liečba správne nastaví, prvé zlepšenie vidno už po mesiaci. Vzhľadom na dĺžku ochorenia celková liečba môže trvať niekoľko mesiacov až rok. Na záver prikladám fotky atopickej kože na členku po pol roku liečby.",
            },
            {
                type: BlogContentType.IMAGE,
                content: EczemaBefore,
            },
            {
                type: BlogContentType.IMAGE,
                content: EczemaAfter,
            },
        ],
        categories: ["akupunktúra", "dermatológia"],
        date: new Date(2020, 7, 12),
        isRecommended: true,
    },
    {
        slug: "co-konzumovat-na-jar",
        title: "Čo konzumovať na jar, aby sme mali dosť energie",
        topImage: Bicycle,
        lede: "Zelené potraviny nám pomôžu zmierniť jarnú únavu a prečistiť organizmus",
        excerpt:
            "Jar nám klope na dvere, tak si poďme povedať, ako by sa so zmenou ročného obdobia mal zmeniť náš jedálniček. Teplejšie a slnečné dni so sebou prinášajú nádielku čerstvej zeleniny. Príroda je múdra a nie je náhoda, že nám tieto dary prináša práve na jar. Mnohí po čerstvej zelenine v tomto období aj intuitívne siahame. Ako a kedy ju konzumovať?",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Jar nám klope na dvere, tak si poďme povedať, ako by sa so zmenou ročného obdobia mal zmeniť náš jedálniček. Teplejšie a slnečné dni so sebou prinášajú nádielku čerstvej zeleniny. Príroda je múdra a nie je náhoda, že nám tieto dary prináša práve na jar. Mnohí po čerstvej zelenine v tomto období aj intuitívne siahame. Ako a kedy ju konzumovať? Je dobré dať si na raňajky zeleninový šalát?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Na jar sa v prírode začína prebúdzať Yang – slnko vyláka spod zeme prvé snežienky, stromy opäť ozelenejú, parky a lesy ožijú vtáčím spevom, narodia sa mláďatká rôznych živočíchov. V prírode sa energia tlačí zvnútra von, všetko rastie a ožíva. Podobne sa to deje aj v našom tele. Viac svetla a tepla nám dodáva yangovú energiu a aj my “ožívame” a vybehneme zo svojich domovov viac do prírody, na bicykle a na výlety.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Avšak, aj jar môže prinášať isté problémy. Nekalé, čo sme cez zimu skonzumovali a uložilo sa nám v tele, chce teraz vyplávať na povrch. Preto niektorým ľuďom na jar vyskočia na koži vyrážky či ekzém alebo sa zhoršia už existujúce kožné neduhy. Ak je naše telo zanesené, nedokáže fungovať na plné obrátky a prichádza jarná únava. Aby sme jarnej únave predišli a pomohli očistiť náš organizmus, je treba podporiť funkciu pečene, ktorá je aktívna najviac práve na jar. V prírode ju predstavuje element drevo. Keďže na jar na “dreve” rastie veľa zelenej, prislúcha jej práve táto farba. Priraďujeme k nej kyslú chuť. Nie je náhoda, že sa vo veľkom zbiera a konzumuje medvedí cesnak. Šikovné gazdinky z neho vyrobia pesto, polievku, fašírky, pridajú ho do rizota alebo dajú len tak do šalátu či na chlebík.",
            },
            {
                type: BlogContentType.IMAGE,
                content: Radish,
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ďalej je vhodné k hlavným jedlám zaradiť rôzne druhy listovej zeleniny, čerstvé bylinky a klíčky. Ak si chce niekto spraviť očistnú kúru so zelenými superpotravinami (spirulina, chlorella, mladý zelený jačmeň a pšenica), jar je ten správny čas. Pre ich chladivú povahu, a tým možnosť zraňovať pri dlhodobom užívaní slezinu, ich nie je vhodné konzumovať celoročne ako sa zvykne odporúčať.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vo všeobecnosti na jar nepotrebujeme veľa mäsa a stravu odľahčíme. Základom sú naďalej obilniny a pridávame viac zeleniny, ktorú kombinujeme tepelne upravenú a surovú. Jedlo menej pečieme a grilujeme. Preferujeme jemnejšie tepelné úpravy – varenie, dusenie a prípravu na pare.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Nápoje konzumujeme naďalej prevažne teplé alebo izbovej teploty. Na podporu čistenia môžeme zaradiť zelené a bylinkové čaje. Nezabúdame ani na najlepší nápoj, ktorým je čaj bez čaju – čistá horúcu voda. Do nápojov môžeme pridať citrónovú šťavu.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Čo nie",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Počas celého roka sa v strave vyhýbame zahlieňujúcim a zachladzujúcim potravinám, ktoré oslabujú slezinu a naše trávenie:",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "nadmernej konzumácii mliečnych výrobkov,",
                    "cukru,",
                    "lepku – najmä pečivu a kysnutému cestu,",
                    "mastným a vyprážaným jedlám,",
                    "nadmernému množstvu surového ovocia a zeleniny,",
                    "mrazeným potravinám,",
                    "chladeným nápojom,",
                    "zmrzline.",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Na záver by som chcela upozorniť, že takéto všeobecné rady podľa TČM sú vhodné pre zdravých ľudí. Pri riešení niektorých ťažkostí môže byť pre určitých ľudí vhodnejšie držať sa rád pre stravovanie v zime aj na jar a v lete či naopak.",
            },
        ],
        categories: ["dietetika"],
        date: new Date(2020, 5, 28),
        isRecommended: true,
    },
    {
        slug: "postcovidovy-syndrom",
        title: "Postcovidový syndróm",
        topImage: Covid,
        lede: "Necítite sa po Covid-19 stále vo svojej koži? Pomôcť vám môže aj čínska medicína.",
        excerpt:
            "Čoraz častejšie sa dozvedám od svojich známych, klientov či od ľudí na sociálnych sieťach o postcovidových problémoch. Žiaľ, v mnohých prípadoch sa človek s už negatívnym testom stále necíti vo svojej koži. Zápas organizmu s takouto razantnou škodlivinou si môže vyžiadať dlhodobejšiu daň.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Čoraz častejšie sa dozvedám od svojich známych, klientov či od ľudí na sociálnych sieťach o postcovidových problémoch. Žiaľ, v mnohých prípadoch sa človek s už negatívnym testom stále necíti vo svojej koži. Zápas organizmu s takouto razantnou škodlivinou si môže vyžiadať dlhodobejšiu daň.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Keďže Covid-19 dokáže spotrebovať všetky zásoby správnej Qi, medzi najčastejšie problémy patrí totálne vyčerpanie. Týka sa aj mladých, športovo zdatných ľudí, ktorí sú zrazu unavení po pár metroch chôdze, či dokonca sa zadýchavajú aj počas rozprávania.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Covid-19 môže poškodiť ktorýkoľvek orgán, no najčastejšie poškodzuje pľúca. Napadnutie pľúc prináša neraz aj trvalé následky vo forme pľúcnej fibrózy, čiže zjazveného pľúcneho tkaniva. Takéto tkanivo už nedokáže plniť svoju funkciu a transportovať kyslík do krvi. Prejavuje sa dýchavičnosťou, suchým kašľom, únavou, úbytkom hmotnosti. Z pohľadu TČM ide o blokádu krvi, ktorú treba opäť rozhýbať.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Covid-19 môže dokonca vstupovať aj do mozgu a spôsobiť dlhodobú mozgovú hmlu, ktorej symptómy sú zmätenosť, nesústredenosť, spomalené vnímanie či strata kognitívnych schopností.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Postcovidový syndróm môže zahŕňať aj iné fyzické či psychické poruchy – problémy s trávením, bolesti hlavy, stratu čuchu a chuti, bolesti svalov či kĺbov, depresie, úzkosti, nespavosť. Niektoré symptómy sa môžu vyskytnúť až niekoľko týždňov po prekonaní Covid-19.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ak chcete liečbu postcovidových syndrómov urýchliť, tak môžete využiť poklady čínskej fytoterapie. Ponúkam v tomto ťažkom období zvýhodnenú diagnostiku (online) zameranú na problémy po Covid-19 a návrh liečby, nech sa naše životy vrátia čím skôr do starých koľají. Pre viac informácií pozrite cenník a v prípade záujmu som tu pre vás.",
            },
        ],
        categories: ["covid", "fytoterapia"],
        date: new Date(2020, 5, 20),
        isRecommended: true,
    },
    {
        slug: "co-jest-jesen-zima",
        title: "Čo jesť pre zdravie na jeseň a v zime",
        topImage: ZimneBobule,
        lede: "Dostatok tepla a energie nám poskytne varená strava. Pijeme hlavne teplú vodu.",
        excerpt:
            "V TČM existuje zopár jednoduchých pravidiel v stravovaní, ktoré pomáhajú udržať si zdravé trávenie. Je známe, že zdravá črevná flóra je základom silnej imunity. V TČM hovoríme o pevnej slezine ako o orgáne, ktorý má na starosti trávenie ako také a zároveň je najdôležitejší orgán pre imunitu.",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V TČM existuje zopár jednoduchých pravidiel v stravovaní, ktoré pomáhajú udržať si zdravé trávenie. Je známe, že zdravá črevná flóra je základom silnej imunity. V TČM hovoríme o pevnej slezine ako o orgáne, ktorý má na starosti trávenie ako také a zároveň je najdôležitejší orgán pre imunitu.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Raňajky",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Každému orgánu prospieva iná charakteristika potravín. Slezine prospieva sladká chuť, potraviny s teplou termodynamikou a žltá farba. Orgánové hodiny pre slezinu sú 9.00-11.00. Odporúča sa zjesť prvé jedlo dňa medzi 7.00 – 9.00 hod, keď sa energia v našom tele sústredí do dráhy žalúdka a potom z neho môže slezina vyťažiť, čo najviac energie a živín. Takéto raňajky by mali byť teplé a prirodzene sladkej alebo fádnej chuti. Najvhodnejšie sú prirodzene sladké obilniny, ktoré sa v chladnejšom období môžu ochutiť škoricou, zázvorom, muškátovým orieškom, kardamomom alebo iným zahrievajúcim korením. V zime nás príjemne zahreje špalda, ovos alebo guľatozrnná ryža. Môžeme z nich uvariť kaše na sladko (s ovocím, orieškami, semienkami) i na slano (s olivovým olejom, dusenou či pečenou zeleninou, vajíčkom). Ďalším ideálnym jedlom na raňajky sú polievky.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Obedy a večere",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Obedy aj večere by takisto mali byť v tomto období čo najviac varené. Dobré jedlo je také, po ktorom sa cítime lepšie ako pred ním. Ak sa po jedle cítime unavení, trpíme plynatosťou, bolesťami brucha alebo hnačkou, tak zloženie a množstvo jedla pre nás nebolo primerané. Večer prirodzene klesá Yangová energia v našom tele a tým pádom aj tráviaci oheň je slabší. Jedlo by už malo byť jednoduché a v menšom množstve, aby sme na noc nezaťažili trávenie a mali sme pokojný a nerušený spánok. Vhodná je napríklad hustá polievka alebo dusená či pečená zelenina.",
            },
            {
                type: BlogContentType.IMAGE,
                content: ZimnaZelenina,
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Jeseň",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Jeseň je pod nadvládou elementu kov. Kov v našom tele predstavujú pľúca a patrí k nemu biela farba a ostrá chuť. Vo varení vo väčšej miere využívame cibuľu, cesnak a pór. Konzumujeme tekvice – pečené, v polievke či v koláči. ďalšími významnými darmi prírody v tomto období sú huby a gaštany. K hlavnému jedlu konzumujeme kvasenú kapustu či inú kvasenú zeleninu – pickles.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Zima",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Zime kraľuje element vody. V našom tele ho predstavujú obličky. Patrí k nemu čierna farba a slaná chuť. Do jedálnička by sme mali zaradiť čiernu fazuľu, čierny sézam, riasy, kešu a vlašské orechy. Mali by sme jedlo dlho variť a pravidelne jesť vývary z mäsa a špikových kostí. Ak ste zimomriví, na úpravu jedla môžete častejšie využiť pečenie v rúre – jedlo vás tak ešte viac zahreje.",
            },
            {
                type: BlogContentType.IMAGE,
                content: Caj,
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Nápoje",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Počas chladných dní pijeme najmä teplú prevarenú vodu, tzv. čaj bez čaju, ako je bežné v Číne. Teplá voda podporí trávenie a zahreje nás. Môžeme tiež popíjať teplý čaj. Pri nedostatku Yangu môžeme deň začať šálkou kávy.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Keď cez deň vonku premrzneme a chceme sa vyhnút prechladnutiu, môžeme vypiť čaj z čerstvého zázvoru, ktorý z nás chlad vyženie. Zázvor nám však otvorí póry, preto potom už nesmieme ísť do zimy. Chladná škodlivina by naopak mohla ľahšie preniknúť do tela a ľahšie by sme ochoreli.",
            },
            {
                type: BlogContentType.SUBTITLE,
                content: "Čo nie",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Vo všeobecnosti sa v strave vyhýbame zahlieňujúcim a zachladzujúcim potravinám, ktoré slezinu oslabujú:",
            },
            {
                type: BlogContentType.LIST,
                content: [
                    "mliečnym výrobkom,",
                    "cukru,",
                    "lepku – najmä pečivu a kysnutému cestu,",
                    "mastným a vyprážaným jedlám,",
                    "nadmernému množstvu surového ovocia a zeleniny,",
                    "mrazeným potravinám,",
                    "chladeným nápojom,",
                    "zmrzline.",
                ],
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Keby som to mala zhrnúť, čo najjednoduchšie, tak nám v chladnom období prospeje čo najviac varenej stravy, z ktorej ľahko získame teplo a energiu Qi (Čchi), ktoré nás ochránia pred preniknutím chladnej škodliviny do tela. Takisto dbáme aj na teplé oblečenie a zakrytý krk.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Dúfam, že vám tieto jednoduché rady pomôžu zorientovať sa v jesennom a zimnom jedálničku a pomôžu vám vyhnúť sa častým návštevam lekárni a doktorov.",
            },
        ],
        categories: ["dietetika"],
        date: new Date(2019, 11, 18),
        isRecommended: true,
    },
    {
        slug: "imunita-deti",
        title: "Ako a kedy posilňovať imunitu detí",
        topImage: Children,
        lede: "Imunitu detí treba začať posilňovať už pred nástupom do škôlky či školy.",
        excerpt:
            "Mnoho detí pri nástupe do škôlky či školy trpí častými virózami. Je normálne mať z času na čas soplík, ale nie je v poriadku byť častejšie chorý ako zdravý. Niekedy sa dokonca aj obyčajná nádcha naťahuje a komplikuje. V tele sa pri vírusovej infekcii môže vytvoriť živná pôda pre baktérie, ktoré sa potom nadmerne rozmnožia a tak prichádzajú na rad antibiotiká. Ako sa vyhnúť kolotoču nádchy, sopľov, kašľu a prípadných antibiotík?",
        content: [
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Mnoho detí pri nástupe do škôlky či školy trpí častými virózami. Je normálne mať z času na čas soplík, ale nie je v poriadku byť častejšie chorý ako zdravý. Niekedy sa dokonca aj obyčajná nádcha naťahuje a komplikuje. V tele sa pri vírusovej infekcii môže vytvoriť živná pôda pre baktérie, ktoré sa potom nadmerne rozmnožia a tak prichádzajú na rad antibiotiká. Ako sa vyhnúť kolotoču nádchy, sopľov, kašľu a prípadných antibiotík?",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Na imunitu našich ratolestí treba začať myslieť už začiatkom augusta. Je to vhodný čas na začiatok posilňovania Wei Qi (obrannej energie). Wei Qi chráni organizmus pred vpádom vonkajšej škodliviny do organizmu, čiže pred vírusmi, baktériami ale aj alergénmi.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pre deti od 3 rokov je obzvlásť vhodný Dračí sirup, ktorý obsahuje zmes medicinálnych húb Reishi a Cordycepsu, koreňa echinaceii, aceroly a vitamínu C. Medicinálne huby obsahujú imunoglobulíny, ktoré zohrávajú významnú rolu v našom imunitnom systéme. Echinacea má silné antivirotické, antibakteriálne a antimykotické účinky. Pôsobí protizápalovo a imunostimulačne. Acerola a vitamín C pomáhajú vstrebávať prospešné látky z medicinálnych húb. Bonusom sirupu je sladká chuť. Sirup je ochutený kakaom a hroznovým sirupom.",
            },
            {
                type: BlogContentType.IMAGE,
                content: DraciSirup,
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Ďalším vhodným receptom pre deti je Yu Ping Feng Wan (Odolnosť ebenového vetrolamu). Jeho zloženie tvoria iba 3 bylinky, pričom najvyššie zastúpenie má Huang Qi (Kozinec blanitý). Je používaný už tisíce rokov pre svoje silné imunomodulačné účinky. V posledných rokoch ho využíva aj západná medicína.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "V prípade vážnejšieho poškodenia imunity je vhodná celková diagnostika a zostavenie individuálneho bylinného receptu.",
            },
            {
                type: BlogContentType.PARAGRAPH,
                content:
                    "Pri budovaní dobrej imunity nesmieme zabúdať na správnu životosprávu. O tom si však napíšeme nabudúce.",
            },
        ],
        categories: ["imunita", "fytoterapia"],
        date: new Date(2019, 9, 2),
        isRecommended: false,
    },
];

const sortedBlogPosts = blogPosts.sort((a, b) => b.date.getTime() - a.date.getTime())

type CategoriesObject = {
    [name: string]: number;
};

const getCategories = (): Category[] => {
    const result: Category[] = [];
    const _categories: CategoriesObject = {};
    blogPosts.forEach((blog) => {
        blog.categories.forEach((category) => {
            if (!_categories[category]) {
                _categories[category] = 1;
            } else {
                _categories[category] += 1;
            }
        });
    });

    Object.entries(_categories).forEach(([name, amount]) => {
        result.push({ name, amount });
    });

    result.sort((a, b) => b.amount - a.amount);

    return result;
};

const categories = getCategories();

export { sortedBlogPosts, categories };
