import { useEffect, useRef, useState } from 'react';
import { scrollHandlerData } from '../../data/LandingPageData';

const ScrollHandler = () => {
    const [hoveredItem, setHoveredItem] = useState('');
    const [isWhiteBackground, setIsWhiteBackground] = useState(false);

    const scrollHandlerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateIsWhiteBackgroud = () => {
            const whiteSections = Array.from(
                document.getElementsByClassName('whitesection')
            );
            const rect1 = whiteSections[0].getBoundingClientRect();
            const rect2 = whiteSections[1].getBoundingClientRect();
            if (
                (window.innerHeight - rect1.top >
                    scrollHandlerRef.current!.getBoundingClientRect().height &&
                    window.innerHeight - rect1.bottom <
                        scrollHandlerRef.current!.getBoundingClientRect()
                            .height) ||
                (window.innerHeight - rect2.top >
                    scrollHandlerRef.current!.getBoundingClientRect().height &&
                    window.innerHeight - rect2.bottom <
                        scrollHandlerRef.current!.getBoundingClientRect()
                            .height)
            ) {
                setIsWhiteBackground(true);
            } else setIsWhiteBackground(false);
        };
        window.addEventListener('scroll', updateIsWhiteBackgroud);
        return () => {
            window.removeEventListener('scroll', updateIsWhiteBackgroud);
        };
    }, []);

    const scrollToSection = (id: string) => {
        const target = document.getElementById(id);

        window.scrollTo({
            top: target!.getBoundingClientRect().top + (window.scrollY || 350),
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div
                ref={scrollHandlerRef}
                className="fixed right-8 bottom-4 flex flex-col gap-2"
            >
                {scrollHandlerData.map((item) => (
                    <div
                        key={item.index}
                        className={`flex flex-row justify-end items-center gap-4 cursor-pointer ${
                            isWhiteBackground ? 'text-dark' : 'text-tertiary'
                        } transition-colors duration-500`}
                        onMouseEnter={() => setHoveredItem(item.index)}
                        onMouseLeave={() => setHoveredItem('')}
                        onClick={() => scrollToSection(item.index)}
                    >
                        {hoveredItem === item.index && (
                            <span className={`px-4 py-2 ${isWhiteBackground ? 'bg-dark text-tertiary' : 'bg-tertiary text-dark'} rounded-3xl transition-colors duration-500`}>
                                {item.text}
                            </span>
                        )}
                        <span className="hover:font-semibold whitesmoke py-2">
                            {item.index}
                        </span>
                    </div>
                ))}
            </div>
            <div
                className={`fixed bottom-4 right-4 h-[232px] w-[1px] ${
                    isWhiteBackground ? 'bg-dark' : 'bg-tertiary'
                } transition-colors duration-500`}
            />
        </>
    );
};
export { ScrollHandler };
