import { motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';
import { Section } from '../shared/Section';
import { SectionHeading } from './SectionHeading';
import {
    AboutTCMContentItem,
    Direction,
    SECTION_COLOR_VARIANTS,
} from '../../types/types';
import { slideIn } from '../../animations/animations';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useEffect, useMemo, useState } from 'react';

type Props = {
    id: string;
    content: AboutTCMContentItem;
};

const AboutTCMSection = ({ id, content }: Props) => {
    const { width } = useWindowSize();
    const [direction, setDirection] = useState<Direction>(Direction.LEFT);

    useEffect(() => {
        if (width && width > 640) {
            setDirection(
                parseInt(id) % 2 === 0 ? Direction.LEFT : Direction.RIGHT
            );
        } else setDirection(Direction.LEFT);
    }, [width, id]);

    const variants = useMemo(() => {
        return slideIn(direction, 0.8, parseInt(id) * 0.2)
    }, [direction, id])

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            variants={variants}
            viewport={{ once: true }}
        >
            <Section id={id} classNames={SECTION_COLOR_VARIANTS[content.color]}>
                <SectionHeading value={content.title} />
                <div
                    className={`flex flex-col ${
                        parseInt(id) % 2 === 0 && 'flex-col-reverse'
                    } xl:flex-row items-center gap-16 xl:gap-12`}
                >
                    {parseInt(id) % 2 !== 0 && (
                        <div className="xl:w-1/2">
                            <img
                                className="object-contain"
                                src={content.image}
                                alt={content.title}
                            />
                        </div>
                    )}
                    <article className="flex flex-col gap-4 md:text-2xl xl:w-1/2">
                        {content.content.map((paragraph) => (
                            <p key={uuid()}>{paragraph}</p>
                        ))}
                    </article>
                    {parseInt(id) % 2 === 0 && (
                        <div className="xl:w-1/2">
                            <img
                                className="object-contain"
                                src={content.image}
                                alt={content.title}
                            />
                        </div>
                    )}
                </div>
            </Section>
        </motion.div>
    );
};
export { AboutTCMSection };
