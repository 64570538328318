import { Photo, Testimonial, TimelineContentType } from "../types/types";
import Katika from '../assets/AboutFYTSpiritImages/hero-image-katika.jpg';

const aboutMeHeroImage: Photo = {
    src: Katika,
    alt: 'Katarína Nagyová Šnircová'
}

const aboutMeContent: string[] = [
    'Som terapeutka čínskej medicíny (TČM) s mnohými skúsenosťami, ktoré som získala počas svojej 8-ročnej praxe v Bratislave. Vo svojej ordinácii som pomohla zlepšiť kvalitu fyzického či duševného zdravia niekoľkým stovkám klientov. V lete 2022 som sa presťahovala do zahraničia, a odvtedy komunikujem s klientami online.',
    'K tradičnej čínskej medicíne ma priviedli moje chronické problémy so zdravím. Od detstva som trpela ekzémami, alergiami, tráviacimi a gynekologickými problémami, na ktoré naša západná medicína nemala dlhodobé riešenie, liečba pomohla len čiastočne a problémy sa opakovane vracali. Pri samoštúdiu zdravého životného štýlu som natrafila aj na literatúru o TČM, vďaka ktorej som pochopila chyby v mojom stravovaní. Upravila som jedálniček podľa princípov TČM a absolvovala som aj bylinnú liečbu. Konečne mi bolo v mnohých ohľadoch lepšie.',
    'Systém TČM sa mi zapáčil, lebo je krásne logický a na rozdiel od našej medicíny sa pozerá na človeka holisticky a hľadá pravú príčinu ochorenia. Aby som mohla pomáhať aj ostatným ľuďom v ich ceste za zdravím, rozhodla som sa pre 4-ročné štúdium tejto medicíny na TCM Institut v Prahe pod vedením MUDr. Jozefa Luckého.',
    'Medzičasom som sa stala aj mamou dvoch detí a veľmi rada pracujem s detičkami, ktoré sú veľmi citlivé a rýchlo reagujú na liečbu. Takisto pracujem s tehotnými ženami, ktoré pripravujem na pôrod po fyzickej i psychickej stránke.',
]

const testimonialsTitle = 'Povedali o mne';

const testimonials: Testimonial[] = [
    {
        author: 'Štefan M.',
        title: 'Liečba kožných problémov',
        content: 'Na atopický ekzém a s ním súvisiace prejavy som vyskúšal rôzne masti, krémy a tabletky. Dostal som sa do štádia, kedy som už nevedel, čo s tým ďalej. Každé ráno sa na posteľnej bielizni objavovali nové krvavé fľaky, na koži som mal ďalšie chrasty a koža bola suchšia a zapálená stále viac. Vtedy som dostal tip na Katku Nagyovú. Rozhodol som sa vyskúšať túto netradičnú cestu liečby a oplatilo sa. Kombinácia upravenej stravy, bylinných odvarov a pravidelných terapií mi pomohli, aby som dostal kožu pod kontrolu. Už po pár týždňoch bol rozdiel viditeľný a aj ja som sa cítil lepšie. Som stále iba na začiatku ale viem, že som na správnej ceste ako sa s ekzémom vysporiadať. Každému, kto hľadá pomoc s rovnakým problémom určite odporúčam vyskúšať.',
    },
    {
        author: 'Veronika P.',
        title: 'Odstránenie fyzických ťažkostí',
        content: 'Spolu s manželom sme zákazníci TCM u Katky Nagyovej Šnircovej. Každého z nás trápil iný problém. Manžel mal zranenú nohu z futbalu, a tak začal chodiť na terapie. Po pár týždňoch bol úplne spokojný s výsledkami a odvtedy s nohou nemá žiaden problém. Ja som mala problém po pôrode s častým močením. Liečila som sa najprv u urológa, ktorý mi predpísal lieky. Po polroku, keď som dobrala lieky, stále ma problém trápil, a tak som sa rozhodla tiež skúsiť čínsku medicínu. Už po 2 mesiacoch som videla výsledky a táto liečba mi naozaj s častým močením pomohla. Takisto mi minulý rok zistili alergiu na buriny a neustále som kýchala a smrkala. Liečba mi celkovo zlepšila zdravotný stav a tento rok som skoro vôbec alergickú reakciu nemala, takže TCM môžem len odporučiť každému, koho trápi akýkoľvek problém.',
    },
    {
        author: 'Petra M.',
        title: 'Pomoc s prekonaním strachu',
        content: 'Pár týždňov pred mojím plánovaným termínom pôrodu som pravidelne chodila ku Katke na čínsku medicínu a doplnkovo som brala aj Bachove kvapky, ktoré mi Katka namiešala na mieru. Oboje ma psychicky ukľudňovalo a cítila som sa až do konca tehotenstva veľmi dobre. Terapia a Bachove kvapky v tomto prípade dokonale zapadli do veľkej skladačky a spolu s ostatnými metódami vytvorili funkčný celok. Postupne som spracovávala môj na počiatku obrovský strach z pôrodu a verím, že aj vďaka tomu bol môj prvý pôrod rýchly a bez komplikácii. Pri pôrode som aj vďaka Bachovým kvapkám ostala prítomná a nepodliehala panike. Katkin individuálny a starostlivý prístup odporúčam a neváham sa do jej rúk zveriť znova, keď to bude potrebné.',
    },
    {
        author: 'Mária H.',
        title: 'Navrátenie životnej energie',
        content: 'Ku Katke som sa dostala v hodine dvanástej. Môj stav, ktorý sa západná medicína snažila liečiť už len ako dôsledky, Katka poňala od príčiny. Kombinácia diéty a liečby urobila zázraky a prvýkrát po 15 rokoch som prežila kvalitné mesiace plné energie a radosti zo života. Milý prístup, ochota počúvať, flexibilita v stretnutiach mi dávajú ten najlepší pocit vytrvať a odporúčať ju ako skvelú terapeutku TČM. Nie som ešte na konci, ale poznám svoje smerovanie a viem, že môžem prísť, ak sa mi zhorší stav. Ďakujem.',
    },
    {
        author: 'Lucia Š.',
        title: 'Katkin priateľský prístup',
        content: 'Katka je skvelá terapeutka a pomohla mi s rôznymi zdravotnými problémami ako sú ženské veci, boľavý chrbát, ale aj tie psychosomatické. Je veľmi chápavá a vie veci aj vysvetliť a poradiť. Určite odporúčam ako terapeutku, či už s akútnymi problémami, alebo chronickými či psychickými, alebo aj preventívne.',
    },
]

const timelineContent: TimelineContentType[] = [
    {
        date: '2010 - 2014',
        title: 'Škola piatich zručností',
        content: [
            'fytoterapia',
            'akupunktúra',
            'tui-na masáže',
            'dietetika',
            'západná medicína',
        ],
        footer: 'TCM Institut, Praha'
    },
    {
        date: '2014',
        title: 'Bi Zheng',
        content: [
            'reumatológia',
            'ochorenia kĺbov',
        ],
        footer: 'TCM Institut, Praha'
    },
    {
        date: '2014',
        title: 'Atopie',
        content: [
            'ekzém',
            'alergia',
            'astma',
        ],
        footer: 'TCM Institut, Praha'
    },
    {
        date: '2014',
        title: 'Gastroenterológia',
        content: [
            'ochorenia tráviacej sústavy',
        ],
        footer: 'TCM Institut, Praha'
    },
    {
        date: '2015',
        title: 'Čínska ušná akupunktúra I a II',
        content: [],
        footer: 'Longmen škola tradičnej čínskej medicíny, Hradec Králové'
    },
    {
        date: '2015',
        title: 'Gu Zheng',
        content: [
            'únavový syndróm',
            'chlamýdie',
            'borelióza',
            'kandidóza',
            'mononukleóza',
            'mozgová hmla',
            'úzkosti, depresia'
        ],
        footer: 'TCM Institut, Praha'
    },
    {
        date: '2016',
        title: 'Bachove kvetové esencie I. a II. stupňa',
        content: [],
        footer: 'MUDr. Dagmar Komárková, Praha'
    },
    {
        date: '2017',
        title: 'Pediatria',
        content: [
            'špecifiká detských ochorení',
            'očkovanie',
        ],
        footer: 'TCM Institut, Praha'
    },
    {
        date: '2017',
        title: 'Gynekológia',
        content: [
            'vybrané kapitoly z Fu Ke',
        ],
        footer: 'MUDr. Zhang JunHua, TCM Institut, Praha'
    },
    {
        date: '2019 - 2021',
        title: 'Kurz pre duly',
        content: [],
        footer: 'OZ Slovenské duly'
    },
    {
        date: '2021',
        title: 'Rebozo ženská a tehotenská starostlivosť',
        content: [],
        footer: 'Anna Kohutová, Bratislava'
    },
]

export { aboutMeHeroImage, aboutMeContent, testimonialsTitle, testimonials, timelineContent }