import { Link } from 'react-router-dom';
import { RecommendedCardPropType } from '../../types/types';

type Props = {
    recommendedList: RecommendedCardPropType[];
};

const RecommendedCard = ({ recommendedList }: Props) => {
    return (
        <div className="bg-tertiary text-dark flex flex-col gap-4 p-8">
            <h3 className="eagle text-xl md:text-3xl">Odporúčané</h3>
            <ul className="flex flex-col gap-1">
                {recommendedList.map((item) => (
                    <li
                        key={item.slug}
                        className="md:text-xl cursor-pointer hover:text-funky hover:font-bold"
                    >
                        <Link to={item.slug}>{item.title}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export { RecommendedCard };
