import { GDPRData } from '../../data/GDPRData';
import { BlogContentObj, BlogContentType } from '../../types/types';
import { ExitBtn } from './SVGs';

type Props = {
    onOutClick: () => void;
};

const GDPR = ({ onOutClick }: Props) => {
    const renderContent = (content: BlogContentObj) => {
        switch (content.type) {
            case BlogContentType.PARAGRAPH:
                return <p className="text-justify">{content.content}</p>;
            case BlogContentType.SUBTITLE:
                return <h5 className="font-bold">{content.content}</h5>;
            case BlogContentType.IMAGE:
                return (
                    typeof content.content === 'string' && (
                        <div className="w-full">
                            <img
                                className="object-contain mx-auto my-6"
                                src={content.content}
                                alt="obrázok"
                            />
                        </div>
                    )
                );
            case BlogContentType.LIST:
                return (
                    typeof content.content !== 'string' && (
                        <ul>
                            {content.content.map((item) => (
                                <li className="list-disc ml-8">{item}</li>
                            ))}
                        </ul>
                    )
                );
            default:
                return;
        }
    };

    return (
        <>
            <div className="fixed w-screen h-screen top-0 left-0 overflow-hidden bg-black opacity-60" onClick={onOutClick}/>
            <div className="fixed left-1/2 -translate-x-1/2 w-full md:w-3/4 lg:w-2/3 h-screen top-0 overflow-y-scroll">
                <section className="relative my-2 p-10  bg-tertiary text-dark opacity-100 ">
                    <h2 className="font-bold text-2xl pb-8">
                        {GDPRData.title}
                    </h2>
                    <div className="flex flex-col gap-4 md:text-xl">
                        {GDPRData.content.map((item) => {
                            return renderContent(item);
                        })}
                    </div>
                    <ExitBtn onExitClick={onOutClick} rightSide />
                </section>
            </div>
        </>
    );
};
export { GDPR };
