import { v4 as uuid } from 'uuid';
import { aboutMeContent, aboutMeHeroImage } from '../../data/AboutMePageData';
import { HeroImage } from '../shared/HeroImage';

const AboutMeCard = () => {
    return (
        <section className="mx-auto w-full bg-primary text-tertiary py-12 flex flex-col gap-16">
            <HeroImage photo={aboutMeHeroImage} />
            <article className='w-full lg:w-2/3 mx-auto flex flex-col gap-8'>
                <h2 className="eagle text-center text-3xl md:text-5xl">
                    Mgr. Katarína Nagyová Šnircová
                </h2>
                <div className="flex flex-col gap-4 text-justify text-lg md:text-2xl">
                    {aboutMeContent.map((paragraph) => (
                        <p key={uuid()}>{paragraph}</p>
                    ))}
                </div>
            </article>
        </section>
    );
};
export { AboutMeCard };
